
import { useLoader } from '../context/LoaderContext';

const Loader = () => {
  const { loading } = useLoader();

  if (!loading) {
    return null; 
  }

  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-80"></div>
      <div
        className={` rounded-lg z-10 w-[94%] sm:w-[400px] relative `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="91"
          height="91"
          viewBox="0 0 31 31"
          fill="none"
          className="mx-auto mt-4 rounded-xl"
        >
          <path d="M0 0H30.456V30.456H0V0Z" fill="#3F9891" />
          <path
            d="M14.4668 4.84375C12.5745 4.84375 10.8371 5.51475 9.4818 6.63142C7.73813 8.07008 6.62646 10.2464 6.62646 12.6838C6.62646 17.0134 10.1371 20.5238 14.4668 20.5238C14.6958 20.5238 14.9228 20.5138 15.1475 20.4948C14.7825 22.3648 14.0341 24.0971 12.9858 25.6084C18.3368 23.8321 22.2135 18.8248 22.3045 12.8984C22.3055 12.8278 22.3068 12.7558 22.3068 12.6838C22.3068 12.6121 22.3055 12.5401 22.3045 12.4691C22.1898 8.23742 18.7255 4.84375 14.4668 4.84375Z"
            stroke="#088F73"
            strokeWidth="1"
            opacity="0.5"
            fill="#ffffff"
          />
          <path
            id="targetPath"
            d="M14.4668 4.84375C12.5745 4.84375 10.8371 5.51475 9.4818 6.63142C7.73813 8.07008 6.62646 10.2464 6.62646 12.6838C6.62646 17.0134 10.1371 20.5238 14.4668 20.5238C14.6958 20.5238 14.9228 20.5138 15.1475 20.4948C14.7825 22.3648 14.0341 24.0971 12.9858 25.6084C18.3368 23.8321 22.2135 18.8248 22.3045 12.8984C22.3055 12.8278 22.3068 12.7558 22.3068 12.6838C22.3068 12.6121 22.3055 12.5401 22.3045 12.4691C22.1898 8.23742 18.7255 4.84375 14.4668 4.84375Z"
            fill="#ffffff"
            stroke="#4B9E98"
            strokeWidth="1.5"
            opacity="1"
          />
        </svg>
      </div>
    </section>
  );
};

export default Loader;
