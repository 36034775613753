import Login from "./screens/admin/login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Welcome from "./screens/admin/welcome";
import ForgetPassword from "./screens/admin/forgetPassowrd";
import CheckYourEmail from "./screens/admin/checkYourEmail";
import SignUP from "./screens/admin/signup";
import NewPassword from "./screens/admin/newPassword";
import ResetPasswordSuccessful from "./screens/admin/resetPasswordSuccessful";
import Sidebar from "./screens/dashboard/sidebar/sidebar";
import { SnackbarProvider } from "notistack";
import ProtectedRoute from "./hooks/protectedRoute";
import { AxiosProvider } from "./hooks/AxiosContext";
import AxiosInterceptor from "./hooks/AxiosInterceptor";
import Loader from "./component/loader";

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <AxiosProvider>
        <BrowserRouter>
          <AxiosInterceptor />
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute redirectToDashboard={true}>
                  <Login />
                </ProtectedRoute>
              }
            />

            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Welcome />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/check-by-email" element={<CheckYourEmail />} />
            <Route path="/sign-up" element={<SignUP />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route
              path="/reset-password-successfull"
              element={<ResetPasswordSuccessful />}
            />
            <Route
              path="/app/*"
              element={
                <ProtectedRoute>
                  <Sidebar />
                </ProtectedRoute>
              }
            />
          </Routes>
          <Loader />
        </BrowserRouter>
      </AxiosProvider>
    </SnackbarProvider>
  );
}

export default App;
