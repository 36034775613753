/* eslint-disable react/prop-types */
import { Building2, X } from "lucide-react";
import PropTypes from "prop-types";

const ListEntity = ({ onClose, entity }) => {
  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div className="rounded-lg z-10 w-[94%] md:w-[640px] relative dark:bg-dark-black-300 bg-white ">
        <div className="py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200">
          <div className="flex justify-start items-center gap-x-4">
            <div
              className={`w-10 h-10 rounded  flex justify-center items-center  drop-shadow-4xl p-2 bg-lighter-zusatzfarben  `}
            >
              <Building2 className="text-dark-zusatzfarben  " size={20} />
            </div>
            <div>
              <h2 className={`text-lg  dark:text-dark-gray-350 text-gray-900 `}>
                List of users
              </h2>
              <p
                className={`text-sm   dark:text-gray-300 text-gray-600 
                `}
              >
                This the list of all users and thier roles in an entity
              </p>
            </div>
          </div>

          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <div className={`py-4 px-4  `}>
          <div className="flex justify-between items-center w-full">
            <p className="w-[45%] text-farbe dark:text-dark-gray-350 text-sm  font-OpenSans-bold md:block hidden">
              User’s Name
            </p>
            <p className="w-[45%] text-farbe dark:text-dark-gray-350 text-sm font-OpenSans-bold md:block hidden">
              User’s Name
            </p>
          </div>
          {entity.users.map((user, index) => (
            <div
              key={index}
              className="flex justify-between items-center w-full mt-5 flex-wrap-reverse"
            >
              <div className="w-full md:w-[45%] flex justify-start items-center gap-x-4 mt-2 md:mt-0">
                <div>
                  <h3 className="text-light-black dark:text-gray-300 text-sm">
                    {user.firstName}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400 text-sm">
                    {user.email}
                  </p>
                </div>
              </div>
              <div className="w-full md:w-[45%] text-light-black dark:text-gray-300 text-sm">
                {user.role?.roleName || "No role assigned"}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ListEntity.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ListEntity;
