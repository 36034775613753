// Create a new file, e.g., AdminUserContext.js
import React, { createContext, useState, useContext } from "react";

const AdminUserContext = createContext();

export const useAdminUserContext = () => useContext(AdminUserContext);

export const AdminUserProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(false);

  const triggerRefresh = () => setRefresh((prev) => !prev);

  return (
    <AdminUserContext.Provider value={{ refresh, triggerRefresh }}>
      {children}
    </AdminUserContext.Provider>
  );
};
