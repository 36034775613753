import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { useAxios } from "../../../hooks/AxiosContext";
import { API_BASE_URL } from "../../../utils/constants";
import { useSnackbar } from "notistack";

const AllPermission = ({ roleId, onClose }) => {
  const axiosInstance = useAxios();
  const [permissions, setPermissions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const handlePermissionChange = (permId, actionType, isChecked) => {
    setPermissions(
      permissions.map((permission) => {
        if (permission.permission_id === permId) {
          return {
            ...permission,
            permissions: {
              ...permission.permissions,
              [actionType]: isChecked,
            },
          };
        }
        return permission;
      })
    );
  };

  const handleSavePermissions = () => {
    console.log("Saving permissions: ", { roleId, permissions });
    axiosInstance
      .post(`${API_BASE_URL}admin/role-permissions`, { roleId, permissions }) // Update with your API endpoint
      .then((response) => {
        // Handle successful save
        enqueueSnackbar("Permissions assign successfully", {
          variant: "success",
        });
        console.log(response.data);
        onClose(); // Close the permission modal
      })
      .catch((error) => {
        // Handle error
        enqueueSnackbar("Error assigning permissions", { variant: "error" });

        console.error("Error saving permissions:", error);
      });
  };
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const allPermissionsResponse = await axiosInstance.get(
          `${API_BASE_URL}admin/permission`
        );
        const currentPermissionsResponse = await axiosInstance.get(
          `${API_BASE_URL}admin/role/${roleId}/permissions`
        );

        const allPermissions = allPermissionsResponse.data;
        const currentPermissions = currentPermissionsResponse.data;

        const updatedPermissions = allPermissions.map((perm) => {
          const currentPerm = currentPermissions.find(
            (cp) => cp.permission_id === perm.permission_id
          );
          const actions = currentPerm
            ? currentPerm.permissions
            : { view: false, create: false, edit: false, delete: false };
          return { ...perm, permissions: actions };
        });

        setPermissions(updatedPermissions);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, []);

  return (
    <>
      <section className="lg:pt-20 pt-16 w-full h-screen overflow-y-auto dark:bg-dark-black-300 bg-white">
        <div className=" py-5 lg:py-8 px-5 lg:px-10 flex justify-between items-center">
          <h2 className="text-xl md:text-2xl  dark:text-dark-gray-350 text-farbe">
            All Permissions
          </h2>
          <button
            className="button-back gap-x-2"
            type="button"
            onClick={onClose}
          >
            Back
          </button>
        </div>
        <div className="w-full px-5 lg:px-10 mt-5 pb-5">
          <div className="flex justify-start gap-x-10 w-full">
            <p className="text-farbe dark:text-dark-gray-350 text-base font-OpenSans-bold w-[15%]">
              MODULE
            </p>
            {/* <p className="text-farbe dark:text-dark-gray-350 text-base font-OpenSans-bold w-[15%]">
              PERMISSIONS
            </p> */}
          </div>
          {permissions.map((permission) => (
            <div
              key={permission.permission_id}
              className="flex justify-start gap-x-10 w-full mt-6"
            >
              <p className="text-farbe dark:text-dark-gray-350 text-sm font-OpenSans-bold w-[25%] xl:w-[15%]">
                {permission.description}
              </p>
              {/* <div className="flex justify-start items-center w-[65%] xl:w-[75%] flex-wrap gap-y-5">
                {["view", "create", "edit", "delete"].map((action) => (
                  <div
                    key={`${permission.permission_id}-${action}`}
                    className="w-[48%] sm:w-[40%] md:w-[30%] lg:w-[23%] xl:w-[15%] flex justify-start items-center gap-x-2"
                  >
                    <input
                      type="checkbox"
                      checked={
                        permission.permissions && permission.permissions[action]
                      }
                      onChange={(e) =>
                        handlePermissionChange(
                          permission.permission_id,
                          action,
                          e.target.checked
                        )
                      }
                      className="w-4 h-4 rounded-sm border border-dark-zusatzfarben checked:text-dark-zusatzfarben checked:bg-dark-zusatzfarben text-dark-zusatzfarben"
                    />
                    <label className="text-light-black dark:text-gray-300 text-sm">
                      {action.charAt(0).toUpperCase() + action.slice(1)}
                    </label>
                  </div>
                ))}
              </div> */}
            </div>
          ))}

          {/* <div className="flex justify-end gap-x-5 mt-7 ">
            <button className="button-cancel  !h-9 gap-x-2" onClick={onClose}>
              Cancel
            </button>
            <button
              onClick={handleSavePermissions}
              className="button-secondary  gap-x-2"
            >
              Save
            </button>
          </div> */}
        </div>
      </section>
    </>
  );
};

AllPermission.propTypes = {
  roleId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default AllPermission;
