import Hints from "../../assets/company/hints-white-logo.png";
import { LockKeyhole, Mail, UnlockKeyhole } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_BASE_URL } from "../../utils/constants";
import Lottie from "lottie-react";
import animationData from "../../assets/json/verified.json";
import { useSnackbar } from "notistack";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [otpMessage, setOtpMessage] = useState(null);
  const [openTwoAuth, setOpenTwoAuth] = useState(true);
  const [openLogin, setopenLogin] = useState(true);
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [otp, setOtp] = useState(Array(6).fill("")); // Array of 6 empty strings
  const [adminData, setAdminData] = useState(null);
  const [showQRCode, setShowQRCode] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [showBackupCode, setShowBackupCode] = useState(false);
  const [showveirfyInput, setShowveirfyInput] = useState(false);
  const [showcantButton, setShowcantButton] = useState(false);
  const [authMessage, setAuthMessage] = useState("");
  const [backupCodes, setBackupCodes] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleTwoAuthSuccessfull = async () => {
    const otpString = otp.join("");
    if (otpString.length === 6) {
      try {
        const response = await fetch(`${API_BASE_URL}admin/verify-2fa`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ adminId: adminData.id, token: otpString }),
        });
        const data = await response.json();
        if (response.ok) {
          localStorage.setItem("adminName", data.name);
          localStorage.setItem("adminEmail", data.email);
          localStorage.setItem("authToken", data.token);
          // Handle successful verification

          setOpenTwoAuth(false);
          // setTimeout(() => {
          //   navigate("/app/dashboard");
          // }, 3000); // 2 seconds delay
        } else {
          // Handle API error
          setOtpMessage({
            type: "error",
            text: data.message || "Invalid OTP",
          });
        }
      } catch (error) {
        setOtpMessage({
          type: "error",
          text: "Network error or server is down",
        });
        console.error("2FA verification error:", error);
      }
    } else {
      setOtpMessage({
        type: "error",
        text: "Please enter the complete Authenticator code",
      });
    }
  };

  const handleOtpChange = (element, index) => {
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // if (element.nextSibling && element.value) {
    //   element.nextSibling.focus();
    // }
    if (element.value === "" && index > 0 && element.previousSibling) {
      element.previousSibling.focus();
    } else if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setMessage(null);
      try {
        const loginResponse = await fetch(`${API_BASE_URL}admin/login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        });
        const loginData = await loginResponse.json();
        if (loginResponse.ok) {
          setopenLogin(false);
          setAdminData(loginData.adminData);
          setMessage({ type: "success", text: "Login successful" });
          if (!loginData.adminData.is2FASetup) {
            setShowQRCode(true);
            setShowOTPInput(true);
            setShowBackupCode(true);
            setAuthMessage(
              "To set up Two-Factor Authentication (2FA) for your account, please scan the QR Code below with your Google Authenticator App. After scanning, enter the verification code provided by the app to complete the setup process."
            );

            // Fetch 2FA QR Code
            const qrResponse = await fetch(
              `${API_BASE_URL}admin/setup-2fa/${loginData.adminData.id}`
            );
            const qrData = await qrResponse.json();
            setQrCodeImage(qrData.QRCodeURL);
            setBackupCodes(qrData.backupCodes);
          } else {
            setShowQRCode(false);
            setShowOTPInput(true);
            setShowcantButton(true);
            setAuthMessage(
              "Please enter the 6-digit verification code generated by your Google Authenticator App to proceed. This additional step ensures enhanced security for your account."
            );
          }
        } else {
          setMessage({
            type: "error",
            text: loginData.message || "Login failed",
          });
        }
      } catch (error) {
        console.error("Login error:", error);
        setMessage({ type: "error", text: "An error occurred" });
      }
      setIsLoading(false);
    },
  });
  const handleDownloadBackupCodes = () => {
    const element = document.createElement("a");
    const file = new Blob([backupCodes.join("\n")], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "backup-codes.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const handleCopyBackupCodes = async () => {
    try {
      await navigator.clipboard.writeText(backupCodes.join("\n"));

      enqueueSnackbar("Backup codes copied to clipboard!", {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar("Failed to copy:", {
        variant: "error",
      });
    }
  };
  const verifyBackupCode = async (code) => {
    const response = await fetch(`${API_BASE_URL}admin/verify-backup-code`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ adminId: adminData.id, backupCode: code }),
    });
    const qrData = await response.json();
    if (response.ok) {
      // Check if the HTTP response status is 200-299
      enqueueSnackbar("Backup code verified! Redirecting to dashboard!", {
        variant: "success",
      });
      localStorage.setItem("adminName", qrData.name);
      localStorage.setItem("adminEmail", qrData.email);
      localStorage.setItem("authToken", qrData.token);
      setTimeout(() => {
        navigate("/app/dashboard");
      }, 3000); // 3 seconds delay
    } else {
      enqueueSnackbar(qrData.message, {
        variant: "error",
      });
    }
  };

  const handleOpenResetForm = () => {
    setShowOTPInput(false);
    setShowveirfyInput(true);
    setShowcantButton(false);
  };
  const handlebackToCodePrompt = () => {
    setShowOTPInput(true);
    setShowveirfyInput(false);
    setShowcantButton(true);
  };
  return (
    <section className="w-full bg-gradient-admin ">
      <div className="w-full xl:max-w-screen-xl lg:container mx-auto px-5 relative flex justify-center items-center min-h-screen flex-col">
        <div className="absolute top-10 left-5">
          <Link to="#">
            <img src={Hints} alt="Hints" title="Hints" />
          </Link>
        </div>
        {openLogin ? (
          <div className="bg-white rounded-xl border border-border-gray p-3 sm:p-5 md:p-9 mx-auto w-full sm:w-[90%] md:w-[540px]">
            <h1 className="text-xl md:text-2xl lg:text-[32px] text-[#333] text-center ">
              Welcome back!
            </h1>
            <form className="mt-5" onSubmit={formik.handleSubmit}>
              <label className="text-[#666] text-sm md:text-base">Email</label>
              <div className="mt-2 relative mb-7">
                <input
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="text-sm w-full outline-none focus:outline-none border border-[#666] rounded-xl py-3 pl-11 pr-5   "
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500 text-xs  absolute -bottom-5 left-1">
                    {formik.errors.email}
                  </div>
                ) : null}

                <Mail
                  className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666]"
                  size={20}
                />
              </div>
              <label className="text-[#666] text-sm md:text-base ">
                Password
              </label>
              <div className="mt-2 relative">
                <input
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  className="text-sm w-full outline-none focus:outline-none border border-[#666] rounded-xl py-3 pl-11 pr-36   "
                />
                {showPassword ? (
                  <UnlockKeyhole
                    className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666] cursor-pointer "
                    size={20}
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <LockKeyhole
                    className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666] cursor-pointer "
                    size={20}
                    onClick={togglePasswordVisibility}
                  />
                )}
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-xs  absolute -bottom-5 left-1">
                    {formik.errors.password}
                  </div>
                ) : null}

                {/* <p className="text-dark-zusatzfarben text-xs sm:text-sm md:text-base font-OpenSans-bold top-1/2 -translate-y-1/2 absolute right-4 cursor-pointer ">
                  <Link to="#">Forgot Password?</Link>
                </p> */}
              </div>
              {message && (
                <div
                  className={`mt-4 text-center py-2 ${
                    message.type === "error" ? "text-red-500" : "text-green-500"
                  }`}
                >
                  {message.text}
                </div>
              )}

              <button
                className="button-primary w-full mt-9"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Login"}
              </button>
            </form>
          </div>
        ) : openTwoAuth ? (
          <div className="bg-white rounded-xl border border-border-gray p-3 sm:p-5 md:p-9 mx-auto w-full sm:w-[90%] md:w-[540px]">
            <h1 className="text-xl md:text-2xl lg:text-[32px] text-[#333] text-center ">
              Two Factor Authentication !
            </h1>

            <p className="text-[#666] text-center text-sm md:text-base mt-2">
              {authMessage}
            </p>
            {showQRCode && (
              <div>
                <img
                  src={qrCodeImage}
                  alt="2FA QR Code"
                  className="max-w-[250px] mx-auto mt-5"
                />
              </div>
            )}
            {showveirfyInput && (
              <div className="items-center justify-center  mt-5">
                <input
                  type="text"
                  placeholder="Enter backup code"
                  value={backupCodes}
                  onChange={(e) => setBackupCodes(e.target.value)}
                  className="px-4 py-3 border border-gray-200 focus:outline-none block w-full  text-sm rounded"
                />
                <button
                  onClick={() => verifyBackupCode(backupCodes)}
                  className="button-primary w-full mt-5"
                >
                  Verify Code
                </button>
              </div>
            )}
            {showOTPInput && (
              <div className="w-fit mx-auto flex justify-center items-center gap-x-2 mt-3">
                <input
                  className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
                  type="text"
                  maxLength="1"
                  size="1"
                  min="0"
                  max="9"
                  value={otp[0]}
                  onChange={(e) => handleOtpChange(e.target, 0)}
                  pattern="[0-9]{1}"
                />
                <input
                  className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
                  type="text"
                  maxLength="1"
                  size="1"
                  min="0"
                  max="9"
                  value={otp[1]}
                  onChange={(e) => handleOtpChange(e.target, 1)}
                  pattern="[0-9]{1}"
                />
                <input
                  className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
                  type="text"
                  maxLength="1"
                  size="1"
                  min="0"
                  max="9"
                  value={otp[2]}
                  onChange={(e) => handleOtpChange(e.target, 2)}
                  pattern="[0-9]{1}"
                />
                <input
                  className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
                  type="text"
                  maxLength="1"
                  size="1"
                  min="0"
                  value={otp[3]}
                  onChange={(e) => handleOtpChange(e.target, 3)}
                  max="9"
                  pattern="[0-9]{1}"
                />
                <input
                  className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
                  type="text"
                  maxLength="1"
                  size="1"
                  min="0"
                  value={otp[4]}
                  onChange={(e) => handleOtpChange(e.target, 4)}
                  max="9"
                  pattern="[0-9]{1}"
                />
                <input
                  className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2  px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
                  type="text"
                  maxLength="1"
                  size="1"
                  value={otp[5]}
                  onChange={(e) => handleOtpChange(e.target, 5)}
                  min="0"
                  max="9"
                  pattern="[0-9]{1}"
                />
              </div>
            )}
            {otpMessage && (
              <div
                className={`mt-4 text-center text-sm  ${
                  otpMessage.type === "error"
                    ? "text-red-500"
                    : "text-green-500"
                }`}
              >
                {otpMessage.text}
              </div>
            )}
            {showOTPInput && (
              <button
                className="button-primary w-full mt-7"
                type="submit"
                onClick={() => handleTwoAuthSuccessfull()}
              >
                Next
              </button>
            )}
            {showcantButton && (
              <button
                className="w-full mt-3 text-sm text-farbe"
                onClick={handleOpenResetForm}
              >
                Can&apos;t access your 2FA codes?
              </button>
            )}
            {showveirfyInput && (
              <button
                className="w-full mt-3 text-sm text-farbe"
                onClick={handlebackToCodePrompt}
              >
                Back to enter code?
              </button>
            )}
          </div>
        ) : (
          <div className="bg-white rounded-xl border border-border-gray p-3 sm:p-5 md:p-9 mx-auto w-full sm:w-[90%] md:w-[540px]">
            <h1 className="text-xl md:text-2xl lg:text-[32px] text-[#333] text-center ">
              Two Factor Authentication Verified!
            </h1>
            <Lottie
              animationData={animationData}
              loop={true}
              autoplay={true}
              className="h-[160px] mt-2"
            />
            {showBackupCode && (
              <div className="bg-white drop-shadow-3xl  rounded px-5  my-4">
                <h2 className="block text-gray-700 text-base md:text-lg font-bold mb-2">
                  Backup Codes
                </h2>
                <p className="text-gray-600 text-sm mb-4">
                  Please save these backup codes in a secure place. You will
                  need them to access your account if you lose your 2FA device.
                </p>

                <div className="bg-gray-100 border border-dashed border-light-zusatzfarben p-4 rounded grid  gap-4">
                  {backupCodes.map((code, index) => (
                    <div
                      key={index}
                      className="text-gray-700 font-mono text-sm py-1 px-3 bg-white rounded shadow"
                    >
                      {code}
                    </div>
                  ))}
                </div>

                <div className="mt-4 flex justify-between ">
                  <button
                    className="button-secondary  "
                    onClick={handleDownloadBackupCodes}
                  >
                    Download
                  </button>
                  <button
                    className="button-cancel h-9"
                    onClick={handleCopyBackupCodes}
                  >
                    Copy to Clipboard
                  </button>
                </div>
              </div>
            )}
            <button
              className="button-back w-full mt-7 !h-12"
              onClick={() => navigate("/app/dashboard")}
            >
              Go to Dashboard
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Login;
