import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAxios } from "./AxiosContext";

const AxiosInterceptor = () => {
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          enqueueSnackbar("Session expired. Please log in again.", {
            variant: "warning",
          });
          localStorage.removeItem("authToken");
          navigate("/login");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [axiosInstance, navigate, enqueueSnackbar]);

  return null;
};

export default AxiosInterceptor;
