import { X } from "lucide-react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaUserEdit } from "react-icons/fa";
import { useAxios } from "../../../../hooks/AxiosContext";
import { useState } from "react";
import { useEffect } from "react";
import { API_BASE_URL } from "../../../../utils/constants";
import { useSnackbar } from "notistack";
import { useAdminUserContext } from "../../../../hooks/AdminUserContext";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("User name is required"),

  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"), // You can adjust the rules as needed
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  // Add other fields as necessary
});
const AddUser = ({ onClose }) => {
  const axiosInstance = useAxios();
  const { triggerRefresh } = useAdminUserContext();

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      firstName: "",

      email: "",

      password: "",
      confirmPassword: "",
      // Initialize other fields here
    },
    validationSchema,
    onSubmit: (values) => {
      axiosInstance
        .post(`${API_BASE_URL}admin/add-admin-user`, values)
        .then((response) => {
          // Handle success
          enqueueSnackbar("User added successfully", {
            variant: "success",
          });
          console.log("User added:", response.data);
          triggerRefresh();
          onClose(); // Close the modal
        })
        .catch((error) => {
          // Handle error
          console.error("Error adding user:", error);
          enqueueSnackbar(error.message, { variant: "error" });
        });
    },
  });

  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div className="rounded-lg z-10 w-[94%] md:w-[640px] relative dark:bg-dark-black-300 bg-white ">
        <div className="py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200">
          <div className="flex justify-start items-center gap-x-4">
            <div
              className={`w-10 h-10 rounded  flex justify-center items-center  drop-shadow-4xl p-2 bg-lighter-zusatzfarben  `}
            >
              <FaUserEdit className="text-dark-zusatzfarben  " size={20} />
            </div>
            <div>
              <h2 className={`text-lg  dark:text-dark-gray-350 text-gray-900 `}>
                Add user
              </h2>
              <p
                className={`text-sm   dark:text-gray-300 text-gray-600 
                `}
              >
                You can Add Admin user details here.
              </p>
            </div>
          </div>

          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <div className={`py-4  `}>
          <form onSubmit={formik.handleSubmit}>
            <div className="px-4">
              <div className="flex justify-between items-center w-full">
                <label className="w-[30%] text-gray-700 dark:text-dark-gray-350  text-sm">
                  User Name
                </label>
                <div className="relative w-[60%]">
                  <input
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    type="text"
                    placeholder="John Doe"
                    className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 outline-none dark:border-farbe dark:text-gray-300 focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="text-red-500 text-xs">
                      {formik.errors.firstName}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex justify-between items-center w-full mt-4">
                <label className="w-[30%] text-gray-700 dark:text-dark-gray-350  text-sm">
                  Email
                </label>
                <div className="relative w-[60%]">
                  <input
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    type="email"
                    placeholder="john@example.com"
                    className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 dark:border-farbe dark:text-gray-300 outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full  "
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-red-500 text-xs">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex justify-between items-center w-full mt-4">
                <label className="w-[30%] text-gray-700 dark:text-dark-gray-350  text-sm">
                  Password
                </label>
                <div className="relative w-[60%]">
                  <input
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    type="password"
                    placeholder="*********"
                    className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 dark:border-farbe dark:text-gray-300 outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-red-500 text-xs">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center w-full mt-4">
                <label className="w-[30%] text-gray-700 dark:text-dark-gray-350  text-sm">
                  Confirm Password
                </label>
                <div className="relative w-[60%]">
                  <input
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    type="password"
                    placeholder="*********"
                    className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 dark:border-farbe dark:text-gray-300 outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full"
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <div className="text-red-500 text-xs">
                        {formik.errors.confirmPassword}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="  border-t dark:border-farbe border-gray-200 pb-4 mt-5 pt-6 w-full md:px-4 flex justify-center gap-x-5  py-4 px-4  ">
              <button type="button" className="button-cancel !w-[50%] h-11">
                Reset
              </button>
              <button type="submit" className="button-secondary !w-[50%] h-11">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

AddUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  refreshUsers: PropTypes.func.isRequired,
};

export default AddUser;
