import {
  ChevronLeft,
  ChevronRight,
  LogOut,
  PenLine,
  Users,
  Download,
} from "lucide-react";
import { useState, useEffect } from "react";
import { BsStripe } from "react-icons/bs";
import LoginAsOwner from "./loginAsOwner";
import EditEntity from "./editEntity";
import StripeAccount from "./stripeAccount";
import ListEntity from "./listEntity";
import { useAxios } from "../../../../hooks/AxiosContext";
import { API_BASE_URL } from "../../../../utils/constants";
import { useLoader } from "../../../../context/LoaderContext";
import { saveAs } from "file-saver";

const Entity = () => {
  const axiosInstance = useAxios();
  const { startLoading, stopLoading } = useLoader();
  const [entities, setEntities] = useState([]);

  //const { entities, setEntities } = useEntity();
  const [openLoginOwner, setOpenLoginOwner] = useState(false);
  const [openEditEntity, setOpenEditEntity] = useState(false);
  const [openStripeAccount, setOpenStripeAccount] = useState(false);
  const [openlistEntity, setOpenlistEntity] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchEntities = async (page = currentPage) => {
    try {
      startLoading();
      const response = await axiosInstance.get(
        `${API_BASE_URL}admin/central-management/entities?page=${page}&limit=10`
      ); // Use your API endpoint here

      setEntities(response.data.entities);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching entities:", error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchEntities();
  }, []);
  // login owner
  const handleLoginOwner = () => {
    setOpenLoginOwner(true);
  };

  const handleCloseLoginOwner = () => {
    setOpenLoginOwner(false);
  };

  //edit Entity

  const handleEditEntity = (entity) => {
    setOpenEditEntity(true);
    setSelectedEntity(entity); // save the selected entity in state
  };
  const handleCloseEditEntity = () => {
    setOpenEditEntity(false);
  };

  // open stripe Account
  const handleOpenStripeAccount = (subId) => {
    setSelectedSubscriptionId(subId);
    setOpenStripeAccount(true);
  };

  const handleCloseStripeAccount = () => {
    setOpenStripeAccount(false);
  };
  // get password
  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      fetchEntities(newPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      fetchEntities(newPage);
    }
  };

  // list

  const handleOpenListEntity = (entity) => {
    setOpenlistEntity(true);
    setSelectedEntity(entity);
  };
  const handleCloseListEntity = () => {
    setOpenlistEntity(false);
  };
  const exportToCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,\uFEFF"; // UTF-8 BOM for Excel compatibility

    // Add the header row
    csvContent +=
      "Name,Url Path,Current Subscription Type,Subscription Timeline,Created At,Owner User\r\n";

    // Add the data rows
    entities.forEach((entity) => {
      const subscription = entity.subscriptions[0] || {};
      const owner = entity.users[0] || {};
      const rowData = [
        `"${entity.companyName}"`,
        `"${entity.subdomain}"`,
        `"${subscription.planName || "No Subscription"}"`,
        subscription.startDate && subscription.endDate
          ? `"${new Date(
              subscription.startDate
            ).toLocaleDateString()} - ${new Date(
              subscription.endDate
            ).toLocaleDateString()}"`
          : `"No Subscription"`,
        `"${new Date(entity.createdAt).toLocaleDateString()}"`,
        `"${owner.firstName || "No Owner"}"`,
      ];
      csvContent += rowData.join(",") + "\r\n";
    });

    // Create a date string for the filename
    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0]; // Yields a date in the format of "YYYY-MM-DD"

    // Generate the filename with the date included
    const filename = `entities_${formattedDate}.csv`;

    // Encode the csvContent
    const encodedUri = encodeURI(csvContent);

    // Create a link and trigger a download
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for Firefox
    link.click(); // Trigger the download
    document.body.removeChild(link); // Clean up
  };

  return (
    <>
      <button
        onClick={exportToCSV}
        className="btn-trasparent absolute right-5  sm:right-[132px] lg:right-[161px] top-[129px] sm:top-[84px] lg:top-[112px]"
        type="button"
      >
        <Download className="mr-2" size={18} /> Export
      </button>
      <table className="w-full responsive-table ">
        <thead>
          <tr>
            <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              Name
            </th>
            <th className="dark:text-dark-gray-350 text-farbe  px-5 py-3 text-base font-OpenSans-bold text-left">
              Url path
            </th>
            <th className="dark:text-dark-gray-350 text-farbe px-5 py-3 text-base font-OpenSans-bold text-left">
              Current subscription type
            </th>
            <th className="dark:text-dark-gray-350 text-farbe px-5 py-3 text-base font-OpenSans-bold text-left">
              Subscription timeline
            </th>
            <th className="dark:text-dark-gray-350 text-farbe px-5 py-3 text-base font-OpenSans-bold text-left">
              Created at
            </th>
            <th className="dark:text-dark-gray-350 text-farbe px-5 py-3 text-base font-OpenSans-bold text-left">
              Owner user
            </th>
            <th className="dark:text-dark-gray-350 text-farbe px-5 py-3 text-base font-OpenSans-bold text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {entities.map((entity) => (
            <tr
              key={entity.entityId}
              className="hover:bg-lighter-white lg:border-b dark:border-farbe border-light-zusatzfarben last:border-0 dark:bg-dark-blue-950 bg-gray-100 lg:bg-transparent mb-4 lg:mb-0 rounded lg:rounded-none lg:dark:bg-transparent   "
            >
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold text-left dark:after:text-gray-300 after:text-black  "
                title="Name"
              >
                {entity.companyName}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold text-left dark:after:text-gray-300 after:text-black"
                title=" Url path"
              >
                {entity.subdomain}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold text-left dark:after:text-gray-300 after:text-black"
                title="Current subscription type"
              >
                {entity.subscriptions[0]?.planName || "No Subscription"}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold text-left dark:after:text-gray-300 after:text-black"
                title="Subscription timeline"
              >
                {entity.subscriptions[0]?.startDate &&
                entity.subscriptions[0]?.endDate
                  ? `${new Date(
                      entity.subscriptions[0].startDate
                    ).toLocaleDateString()} - ${new Date(
                      entity.subscriptions[0].endDate
                    ).toLocaleDateString()}`
                  : "No Subscription"}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold text-left dark:after:text-gray-300 after:text-black"
                title=" Created at"
              >
                {new Date(entity.createdAt).toLocaleDateString()}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold text-left dark:after:text-gray-300 after:text-black"
                title=" Owner user"
              >
                {entity.users[0]?.firstName || "No Owner"}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold text-left dark:after:text-gray-300 after:text-black"
                title="Actions"
              >
                <ul className="flex justify-start items-center gap-x-3">
                  <li>
                    <LogOut
                      className="dark:text-gray-300 text-farbe cursor-pointer hover:text-dark-zusatzfarben "
                      size={18}
                      onClick={() => handleLoginOwner()}
                    />
                  </li>
                  <li>
                    <PenLine
                      onClick={() => handleEditEntity(entity)}
                      className="dark:text-gray-300 text-farbe cursor-pointer hover:text-blue-500  "
                      size={18}
                    />
                  </li>
                  <li>
                    <BsStripe
                      onClick={() =>
                        handleOpenStripeAccount(
                          entity.subscriptions[0]?.stripeSubscriptionId
                        )
                      }
                      className="dark:text-gray-300 text-farbe cursor-pointer hover:text-blue-500  "
                      size={20}
                    />
                  </li>

                  <li>
                    <Users
                      onClick={() => handleOpenListEntity(entity)}
                      className="dark:text-gray-300 text-farbe cursor-pointer hover:text-dark-zusatzfarben   "
                      size={18}
                    />
                  </li>
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="border-t border-[#D3EDEA] dark:border-farbe mt-10 py-3 lg:px-5 flex justify-between items-center">
        <button
          type="button"
          className="button-secondary !w-[84px] "
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <ChevronLeft className="text-white mr-2" size={20} /> Prev
        </button>
        <p className="text-sm text-black dark:text-gray-300 ">
          Page{" "}
          <span className="text-farbe dark:text-gray-300 font-OpenSans-ExtraBold">
            {currentPage}
          </span>{" "}
          of {totalPages}
        </p>
        <button
          type="button"
          className="button-secondary !w-[84px]"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next <ChevronRight className="text-white ml-2" size={20} />
        </button>
      </div>

      {openLoginOwner && <LoginAsOwner onClose={handleCloseLoginOwner} />}
      {openEditEntity && (
        <EditEntity
          onClose={handleCloseEditEntity}
          entity={selectedEntity}
          refreshEntities={fetchEntities}
        />
      )}
      {openStripeAccount && (
        <StripeAccount
          onClose={handleCloseStripeAccount}
          stripeSubscriptionId={selectedSubscriptionId}
        />
      )}
      {openlistEntity && (
        <ListEntity onClose={handleCloseListEntity} entity={selectedEntity} />
      )}
    </>
  );
};

export default Entity;
