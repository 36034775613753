import { Navigate } from "react-router-dom";
import Login from "../screens/admin/login";
import Welcome from "../screens/dashboard/welcome/index.jsx";
import Sidebar from "../screens/dashboard/sidebar/sidebar.jsx";
const ProtectedRoute = ({ children, redirectToDashboard = false }) => {
  const authToken = localStorage.getItem("authToken");
  if (!authToken) {
    return <Navigate to="/login" />;
  }
  if (authToken && redirectToDashboard) {
    return <Navigate to="/app/dashboard" />;
  }
  return children;
};

export default ProtectedRoute;
