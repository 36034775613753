import Hints from "../../assets/company/hints-white-logo.png";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "../../assets/json/Animation - 1703658918521.json";

const Welcome = () => {
  return (
    <section className="w-full bg-gradient-admin ">
      <div className="w-full xl:max-w-screen-xl lg:container mx-auto px-5 relative flex justify-center items-center min-h-screen flex-col">
        <div className="absolute top-10 left-0">
          <Link to="#">
            <img src={Hints} alt="Hints" title="Hints" />
          </Link>
        </div>
        <div className="bg-white rounded-xl border border-border-gray p-3 sm:p-5 md:p-9 w-full sm:w-[90%] md:w-[540px]">
          <h1 className="text-xl md:text-2xl lg:text-[32px] text-[#333] text-center ">Welcome!</h1>
          <p className="text-[#666] text-center text-sm md:text-base mt-2">
            Your courage in using Hints to bring light to the shadows is
            commendable.
          </p>
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            className="h-[260px] mt-2"
          />
          <button className="button-primary w-full mt-4" type="submit">
            Continue
          </button>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
