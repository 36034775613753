import { X } from "lucide-react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaUserEdit } from "react-icons/fa";
import { useAxios } from "../../../../hooks/AxiosContext";
import { useState } from "react";
import { useEffect } from "react";
import { API_BASE_URL } from "../../../../utils/constants";
import { useSnackbar } from "notistack";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  roleId: Yup.number().required("Role is required"),

  // Add other fields as necessary
});
const EditUser = ({ onClose, user, refreshUsers }) => {
  console.log(user);
  const axiosInstance = useAxios();
  const [roles, setRoles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}admin/roles`);
        setRoles(response.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      email: user.email || "",
      roleId: user.roles && user.roles.length > 0 ? user.roles[0].roleId : "",
      title: user.title || "",
      userId: user.userId || "",
      // Initialize other fields here
    },
    validationSchema,
    onSubmit: (values) => {
      axiosInstance
        .post(`${API_BASE_URL}admin/update-user`, values)
        .then((response) => {
          // Handle success
          enqueueSnackbar("User updated successfully", {
            variant: "success",
          });
          console.log("User updated:", response.data);
          refreshUsers();
          onClose(); // Close the modal
        })
        .catch((error) => {
          // Handle error
          console.error("Error updating user:", error);
          enqueueSnackbar("Error updating user:", { variant: "error" });
        });
    },
  });
  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div className="rounded-lg z-10 w-[94%] md:w-[640px] relative dark:bg-dark-black-300 bg-white ">
        <div className="py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200">
          <div className="flex justify-start items-center gap-x-4">
            <div
              className={`w-10 h-10 rounded  flex justify-center items-center  drop-shadow-4xl p-2 bg-lighter-zusatzfarben  `}
            >
              <FaUserEdit className="text-dark-zusatzfarben  " size={20} />
            </div>
            <div>
              <h2 className={`text-lg  dark:text-dark-gray-350 text-gray-900 `}>
                Edit user
              </h2>
              <p
                className={`text-sm   dark:text-gray-300 text-gray-600 
                `}
              >
                You can edit user details here.
              </p>
            </div>
          </div>

          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <div className={`py-4   `}>
          <form onSubmit={formik.handleSubmit}>
            <div className="px-4">
            <div className="flex justify-between items-center w-full flex-wrap">
              <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350    text-sm">
                First Name
              </label>
              <div className="relative w-full mt-2 md:mt-0 md:w-[60%]">
                <input
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  type="text"
                  placeholder="e.g. Jenny "
                  className="py-2 px-3 text-gray-700 text-sm md:text-base dark:text-gray-300 placeholder:text-gray-700 outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full"
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="text-red-500 text-xs">
                    {formik.errors.firstName}
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center w-full mt-4 flex-wrap">
              <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350  text-sm">
                Last Name
              </label>
              <div className="relative w-full mt-2 md:mt-0 md:w-[60%]">
                <input
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  type="text"
                  placeholder="codian.com"
                  className="py-2 px-3 text-gray-700 text-sm md:text-base dark:text-gray-300 placeholder:text-gray-700 outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full"
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="text-red-500 text-xs">
                    {formik.errors.lastName}
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center w-full mt-4 flex-wrap">
              <label className="w-full md:w-[30%] text-gray-700  dark:text-dark-gray-350 text-sm">
                Email
              </label>
              <div className="relative w-full mt-2 md:mt-0 md:w-[60%]">
                <input
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  type="email"
                  placeholder="jenny@untitledui.com"
                  className="py-2 px-3 text-gray-700  text-sm md:text-base placeholder:text-gray-700 dark:text-gray-300 outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full  "
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-red-500 text-xs">
                    {formik.errors.email}
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between items-center w-full mt-4 flex-wrap">
              <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350  text-sm">
                Role
              </label>
              <select
                name="roleId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.roleId}
                className="py-2 px-3 text-gray-700 text-sm md:text-base placeholder:text-gray-700 dark:text-gray-300 outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full mt-2 md:mt-0 md:w-[60%] "
              >
                <option value="" disabled>
                  Select a role
                </option>
                {roles.map((role) => (
                  <option key={role.roleId} value={role.roleId}>
                    {role.roleName}
                  </option>
                ))}
              </select>
              {formik.touched.roleId && formik.errors.roleId && (
                <div className="text-red-500 text-xs">
                  {formik.errors.roleId}
                </div>
              )}
            </div>
            <div className="flex justify-between items-center w-full mt-4 mb-4 flex-wrap">
              <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm">
                Title
              </label>
              <input
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                type="text"
                placeholder="this is title"
                className="py-2 px-3 text-gray-700 text-sm md:text-base placeholder:text-gray-700 dark:text-gray-300 outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full mt-2 md:mt-0 md:w-[60%] "
              />
            </div>
            </div>
            <div className="  border-t dark:border-farbe border-gray-200 pb-4 pt-6 w-full md:px-4 flex justify-center gap-x-5 ">
              <button type="button" className="button-cancel !w-[50%] h-11">
                Reset
              </button>
              <button type="submit" className="button-secondary !w-[50%] h-11">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

EditUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  refreshUsers: PropTypes.func.isRequired, // Add this line
};

export default EditUser;
