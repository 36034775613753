import { Route, Routes } from "react-router-dom";
import Roles from "../screens/dashboard/rolesAndPermission/roles";
import Dashboard from "../screens/dashboard/welcome/index";
import ActivityTracking from "../screens/dashboard/activityTracking";
import ResolutionHub from "../screens/dashboard/resolutionHub/index";
import CentralManagement from "../screens/dashboard/centralManagementPanel";
import Category from "../screens/dashboard/blog/category";
import BlogArticle from "../screens/dashboard/blog/blogArticle";
import AddBlog from "../screens/dashboard/blog/blogArticle/addBlog.jsx";

const Routing = () => {
  return (
    <Routes>
      <>
        <Route path="/roles-and-permission" element={<Roles />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/activity-tracking" element={<ActivityTracking />} />
        <Route path="/resolution-hub" element={<ResolutionHub />} />
        <Route path="/central-management" element={<CentralManagement />} />
        <Route path="/blogs/category" element={<Category/>} />
        <Route path="/blogs/blog-article" element={<BlogArticle/>} />
        <Route path="/blogs/add-new" element={<AddBlog/>} />
      </>
    </Routes>
  );
};

export default Routing;
