import PropTypes from "prop-types";

const AllPermission = ({ onClose }) => {
  return (
    <>
      <section className="pt-16 lg:pt-20 w-full h-screen overflow-y-auto dark:bg-dark-black-300 bg-white">
        <div className=" pt-10 pb-16 px-5 lg:px-10 bg-white dark:bg-dark-blue-950 drop-shadow-3xl rounded-lg w-full ">
          <div className="border-l-4 border-dark-zusatzfarben px-5 lg:px-10 py-3 gap-y-2 flex-wrap flex justify-between items-center ">
            <div>
              <h3 className="text-lg text-[#101828] dark:text-dark-gray-350 ">
                admin1{" "}
                <span className="text-farbe dark:text-dark-gray-350 text-sm">
                  {" "}
                  (6 months ago)
                </span>
              </h3>
              <p className="text-base text-[#475467] dark:text-gray-400 ">
                admin@example.com
              </p>
            </div>
            <div className="flex justify-start items-center gap-x-2">
              <p className="text-[#101828] dark:text-dark-gray-350 text-base font-OpenSans-bold">
                Status:
              </p>
              <div className="border border-[#34A853] text-sm p-[2px] rounded-xl bg-green-50  min-w-[70px] max-w-[90px] mx-auto w-full text-[#34A853] flex justify-center items-center">
                Closed
              </div>
            </div>
          </div>
          <div className="px-5 lg:px-10 mt-5">
            <p className="text-[#475467] dark:text-gray-300 text-base">
              {`These actions include changes to passwords, account recovery
              details (telephone numbers, email addresses), and 2-Step
              Verification enrollment. A secure password and updated recovery
              info help protect your Google Account. Your password should be
              hard to guess and shouldn't contain personal information like your
              birth date or phone number.`}
            </p>
          </div>
          <div className="px-5 lg:px-10 mt-5">
            <h3 className="text-[#101828] dark:text-dark-gray-350 text-base">
              Attachments
            </h3>
          </div>
        </div>
        <div className="px-5 lg:px-10 pb-10">
          <div className="border-l-4 border-dark-zusatzfarben px-5 lg:px-10 py-3 flex justify-between items-center border-b-gray-200 ">
            <h3 className="text-lg text-[#101828] dark:text-dark-gray-350 ">
              Add reply
            </h3>
          </div>
          <p className="text-[#666] dark:text-gray-400 text-sm font-OpenSans-bold  mt-10 mb-4">
            Description
          </p>
          {/* <div className="dark:hidden block">
            <Editor
              initialValue="<p>Write  the message here!</p>"
              init={{
                height: 250,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style: "body {  font-size:14px }",
              }}
            />
          </div>
          <div className="black dark:block hidden">
            <Editor
              initialValue="<p>Write  the message here!</p>"
              init={{
                height: 250,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div> */}

          <p className="text-[#666] dark:text-gray-400  text-sm font-OpenSans-bold  mt-4 ">
            Attachments
          </p>
          <div className="upload-btn-wrapper w-full flex justify-start relative mt-3">
            <input
              type="file"
              className="border-l border-t border-b border-gray-300 rounded-l bg-white drop-shadow-4xl py-2 px-3 w-full text-base placeholder:text-dark-zusatzfarben outline-none text-dark-zusatzfarben  cursor-pointer  "
              placeholder=""
            />
            <div className="flex justify-start  w-full cursor-pointer ">
              <div className="rounded-l-lg text-center bg-dark-zusatzfarben text-white text-sm whitespace-nowrap md:text-base py-3 px-3 w-[150px]   ">
                Choose files
              </div>
              <div className="border border-gray-300 dark:border-farbe rounded-r-lg w-full"></div>
            </div>
          </div>
          <div className="flex justify-end items-center gap-x-5 mt-5">
            <button
              type="button"
              className="button-cancel !w-[125px] h-10"
              onClick={onClose}
            >
              Cancel
            </button>
            <button type="button" className="button-secondary !w-[125px] h-10">
              Save
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

AllPermission.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default AllPermission;
