import Hints from "../../assets/company/hints-white-logo.png";
import { Link } from "react-router-dom";
import { Mail, MoveLeft } from "lucide-react";
import Lottie from "lottie-react";
import animationData from "../../assets/json/forget.json";

const ForgetPassword = () => {
  return (
    <section className="w-full bg-gradient-admin ">
      <div className="w-full xl:max-w-screen-xl lg:container mx-auto px-5 relative flex justify-center items-center min-h-screen flex-col">
        <div className="absolute top-10 left-0">
          <Link to="#">
            <img src={Hints} alt="Hints" title="Hints" />
          </Link>
        </div>
        <div className="bg-white rounded-xl border border-border-gray p-3 sm:p-5 md:p-9 w-full sm:w-[90%] md:w-[540px]">
          <h1 className="text-xl md:text-2xl lg:text-[32px] text-[#333] text-center ">
            Forget Password
          </h1>
          <p className="text-[#666] text-center text-sm md:text-base mt-2">
            Don’t worry we we’ll send you reset instructions.
          </p>
           <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            className="h-[160px] mt-2"
          />
          <form >
            <label className="text-[#666] text-sm md:text-base">Email</label>
            <div className="mt-2 relative ">
              <input
                type="text"
                className="text-sm w-full outline-none focus:outline-none border border-[#666] rounded-xl py-3 pl-11 pr-5   "
              />
              <Mail
                className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666]"
                size={20}
              />
            </div>
          </form>
          <button className="button-primary w-full mt-6" type="submit">
            Reset password
          </button>

          <div className="w-fit mx-auto flex justify-center items-center gap-x-4 mt-4">
            <Link
              to="/login"
              className="flex justify-center items-center gap-x-3 text-dark-zusatzfarben text-sm md:text-base"
            >
              <MoveLeft />
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
