import Hints from "../../assets/company/hints-white-logo.png";
import { LockKeyhole, MoveLeft, UnlockKeyhole } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

const NewPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <section className="w-full bg-gradient-admin ">
      <div className="w-full xl:max-w-screen-xl lg:container mx-auto px-5 relative flex justify-center items-center min-h-screen flex-col">
        <div className="absolute top-10 left-0">
          <Link to="#">
            <img src={Hints} alt="Hints" title="Hints" />
          </Link>
        </div>
        <div className="bg-white rounded-xl border border-border-gray p-3 sm:p-5 md:p-9 w-full sm:w-[90%] md:w-[540px]">
          <h1 className="text-xl md:text-2xl lg:text-[32px] text-[#333] text-center ">
            Set new password
          </h1>
          <p className="text-[#666] text-center text-sm md:text-base mt-2">
            Your new password must be different from previous one.
          </p>
          <form className="mt-5">
            <label className="text-[#666] text-sm md:text-base">New password</label>
            <div className="mt-2 relative mb-5">
              <input
                type={showPassword ? "text" : "password"}
                className="text-sm w-full outline-none focus:outline-none border border-[#666] rounded-xl py-3 pl-11 pr-36   "
              />
              {showPassword ? (
                <UnlockKeyhole
                  className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666] cursor-pointer"
                  size={20}
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <LockKeyhole
                  className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666] cursor-pointer"
                  size={20}
                  onClick={togglePasswordVisibility}
                />
              )}
              <p className="text-dark-zusatzfarben text-sm md:text-base font-OpenSans-bold top-1/2 -translate-y-1/2 absolute right-4  ">
                {showPassword ? "Show" : "Hide"}
              </p>
            </div>
            <label className="text-[#666] text-sm md:text-base ">Confirm password</label>
            <div className="mt-2 relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="text-sm w-full outline-none focus:outline-none border border-[#666] rounded-xl py-3 pl-11 pr-36   "
              />
              {showConfirmPassword ? (
                <UnlockKeyhole
                  className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666] cursor-pointer"
                  size={20}
                  onClick={toggleConfirmPasswordVisibility}
                />
              ) : (
                <LockKeyhole
                  className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666] cursor-pointer"
                  size={20}
                  onClick={toggleConfirmPasswordVisibility}
                />
              )}
              <p className="text-dark-zusatzfarben text-sm md:text-base font-OpenSans-bold top-1/2 -translate-y-1/2 absolute right-4  ">
                {showConfirmPassword ? "Show" : "Hide"}
              </p>
            </div>

            <button className="button-primary w-full mt-7" type="submit">
              Reset password
            </button>
          </form>
          <div className="w-fit mx-auto flex justify-center items-center gap-x-4 mt-4">
            <Link
              to="/login"
              className="flex justify-center items-center gap-x-3 text-dark-zusatzfarben text-sm md:text-base"
            >
              <MoveLeft />
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewPassword;
