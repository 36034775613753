import Hints from "../../assets/company/hints-white-logo.png";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "../../assets/json/check-your-email.json";

const CheckYourEmail = () => {
  return (
    <section className="w-full bg-gradient-admin ">
      <div className="w-full xl:max-w-screen-xl lg:container mx-auto px-5 relative flex justify-center items-center min-h-screen flex-col">
        <div className="absolute top-10 left-0">
          <Link to="#">
            <img src={Hints} alt="Hints" title="Hints" />
          </Link>
        </div>
        <div className="bg-white rounded-xl border border-border-gray p-3 sm:p-5 md:p-9 w-full sm:w-[90%] md:w-[540px]">
          <h1 className="text-xl md:text-2xl lg:text-[32px] text-[#333] text-center ">
            Check your email
          </h1>
          <p className="text-[#666] text-center text-sm md:text-base mt-2">
            We’ve sent a code to your email id.
          </p>
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            className="h-[190px] mt-2"
          />
          <form>
            <label className="text-[#666] text-sm md:text-base">Confirmation code</label>
            <div className="w-fit mx-auto flex justify-center items-center gap-x-2">
          <input
            className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2 px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          <input
            className="border-b-2 border-farbe hover:border-dark-zusatzfarben text-center pb-2  px-1 outline-none focus:outline-none text-sm sm:text-xl md:text-2xl lg:text-[32px]  "
            type="text"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="[0-9]{1}"
          />
          </div>
          </form>
          <button className="button-primary w-full mt-8" type="submit">
            Confirm
          </button>

          <div className="w-fit mx-auto flex justify-center items-center gap-x-2 mt-4">
            Didn’t received the code?
            <Link
              to="/login"
              className="flex justify-center items-center gap-x-3 text-dark-zusatzfarben  text-sm md:text-base"
            >
              Resend code
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckYourEmail;
