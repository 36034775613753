import { LogOut, X } from "lucide-react";
import PropTypes from "prop-types";

const LoginAsOwner = ({ onClose }) => {
  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div className="rounded-lg z-10 w-[94%] sm:w-[460px] relative dark:bg-dark-black-300 bg-white ">
        <div className="py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200">
          <div className="flex justify-start items-center gap-x-4">
            <div
              className={`w-10 h-10 rounded  flex justify-center items-center  drop-shadow-4xl p-2  bg-lighter-zusatzfarben `}
            >
              <LogOut className="text-dark-zusatzfarben  " size={20} />
            </div>
          </div>

          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <div
          className={`py-4 px-4 border-b  dark:border-farbe border-gray-200 `}
        >
          <h2 className={`text-lg  dark:text-dark-gray-350 text-center text-gray-900 `}>
            Are you sure you want to login as owner?
          </h2>
        </div>
        <div className="   py-4 px-4 w-full flex justify-center gap-x-5 ">
          <button type="button" className="button-secondary !w-[50%] h-11">
            Yes
          </button>
          <button type="button" className="button-cancel !w-[50%] h-11">
            No
          </button>
        </div>
      </div>
    </section>
  );
};

LoginAsOwner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LoginAsOwner;
