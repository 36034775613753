import {
  ChevronLeft,
  ChevronRight,
  PenLine,
  Trash2,
  Download,
} from "lucide-react";
import { useState } from "react";
import EditUser from "./editUser";
import { useLoader } from "../../../../context/LoaderContext";
import { useAxios } from "../../../../hooks/AxiosContext"; // Import useAxios
import { API_BASE_URL } from "../../../../utils/constants"; // Import API_BASE_URL
import { useEffect } from "react";
import DeleteAdminUser from "./deleteUser";
import { useSnackbar } from "notistack";
import { useAdminUserContext } from "../../../../hooks/AdminUserContext";

const AdminUser = () => {
  const axiosInstance = useAxios();
  const [users, setUsers] = useState([]); // State to store users
  const { refresh } = useAdminUserContext();
  const { startLoading, stopLoading } = useLoader();
  const [openEditUser, setOpenEditUser] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [openDeleteAdminUser, setOpenDeleteAdminUser] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const fetchUsers = async (page = currentPage) => {
    try {
      startLoading();
      const response = await axiosInstance.get(
        `${API_BASE_URL}admin/admin-users?page=${page}&limit=10`
      );
      setUsers(response.data.admins);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      stopLoading();
    }
  };

  // Call fetchUsers when the component mounts
  useEffect(() => {
    fetchUsers();
  }, [refresh]);

  //edit User
  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      fetchUsers(newPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      fetchUsers(newPage);
    }
  };
  const handleEditUser = (user) => {
    setOpenEditUser(true);
    setSelectedUser(user); // Save the selected user in state
  };
  const handleDeleteAdminUser = (adminId) => {
    setSelectedAdminId(adminId);
    setOpenDeleteAdminUser(true);
  };
  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };
  const deleteAdminUser = (adminId) => {
    axiosInstance
      .delete(`${API_BASE_URL}admin/admin-users/${adminId}`) // API call to delete the role
      .then((response) => {
        enqueueSnackbar("User deleted successfully", { variant: "success" });
        console.log("User deleted:", response.data);
        fetchUsers(currentPage); // Refresh the list after deletion
        setOpenDeleteAdminUser(false); // Close the deletion confirmation modal
      })
      .catch((error) => {
        enqueueSnackbar("Error deleting user", { variant: "error" });

        // Optionally show an error message to the user
      });
  };
  const exportToCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add the header row
    csvContent += "Full Name,Email\r\n"; // \r\n for a new line in CSV format

    // Add the data rows
    users.forEach((user) => {
      const rowData = [
        user.name, // Assuming 'name' is the correct property
        user.email,
      ];
      csvContent += rowData.join(",") + "\r\n"; // \r\n for a new line in CSV format
    });

    // Convert the CSV content to a Blob and save it as a file
    const encodedUri = encodeURI(csvContent); // Encode the CSV content
    const link = document.createElement("a"); // Create a link element
    link.setAttribute("href", encodedUri);
    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0]; // Yields a date in the format of "YYYY-MM-DD"
    const filename = `admin_users${formattedDate}.csv`;
    link.setAttribute("download", filename);

    document.body.appendChild(link); // Required for FF
    link.click(); // Simulate click to trigger download
    document.body.removeChild(link); // Clean up
  };

  return (
    <>
      <button
        onClick={exportToCSV}
        className="btn-trasparent absolute right-5  sm:right-[295px] lg:right-[314px] top-[129px] sm:top-[84px] lg:top-[112px]"
        type="button"
      >
        <Download className="mr-2" size={18} /> Export
      </button>

      <table className="w-full responsive-table ">
        <thead>
          <tr>
            <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              Full name
            </th>

            <th className="text-farbe  dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              Email
            </th>

            <th className="text-farbe  dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr
              key={user.admin_id}
              className="hover:bg-lighter-white lg:border-b dark:border-farbe border-light-zusatzfarben last:border-0 dark:bg-dark-blue-950 bg-gray-100 lg:bg-transparent mb-4 lg:mb-0 rounded lg:rounded-none lg:dark:bg-transparent"
            >
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                title="Full name"
              >
                {user.name}
              </td>

              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                title="Email"
              >
                {user.email}
              </td>

              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                title="Actions"
              >
                <ul className="flex justify-start items-center gap-x-3">
                  <li>
                    <PenLine
                      onClick={() => handleEditUser(user)}
                      className="text-farbe dark:text-gray-300 cursor-pointer hover:text-blue-500  "
                      size={18}
                    />
                  </li>
                  <li>
                    <Trash2
                      className="text-farbe dark:text-gray-300 cursor-pointer hover:text-redish "
                      size={18}
                      onClick={() => handleDeleteAdminUser(user.admin_id)}
                    />
                  </li>
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="border-t border-[#D3EDEA] dark:border-farbe mt-10 py-3 md:px-5 flex justify-between items-center">
        <button
          type="button"
          className="button-secondary !w-[84px]"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <ChevronLeft className="text-white mr-2" size={20} /> Prev
        </button>
        <p className="text-sm text-black dark:text-gray-300">
          Page{" "}
          <span className="text-farbe dark:text-dark-gray-350 font-OpenSans-ExtraBold">
            {currentPage}
          </span>{" "}
          of {totalPages}
        </p>
        <button
          type="button"
          className="button-secondary !w-[84px]"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next <ChevronRight className="text-white ml-2" size={20} />
        </button>
      </div>

      {openEditUser && (
        <EditUser
          onClose={handleCloseEditUser}
          user={selectedUser}
          refreshUsers={fetchUsers}
        />
      )}
      {openDeleteAdminUser && (
        <DeleteAdminUser
          onClose={() => setOpenDeleteAdminUser(false)}
          onDelete={deleteAdminUser}
          adminId={selectedAdminId}
        />
      )}
    </>
  );
};

export default AdminUser;
