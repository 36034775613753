import Hints from "../../assets/company/hints-white-logo.png";
import { Link } from "react-router-dom";
import ResetPassword from "../../assets/admin/reset-password.png";
// import PartnersLogo from "../../assets/company/partnersLogo.png";

const ResetPasswordSuccessful = () => {
  return (
    <section className="w-full bg-gradient-admin ">
      <div className="w-full xl:max-w-screen-xl lg:container mx-auto px-5 relative flex justify-center items-center min-h-screen flex-col">
        <div className="absolute top-10 left-0">
          <Link to="#">
            <img src={Hints} alt="Hints" title="Hints" />
          </Link>
        </div>
        <div className="bg-white rounded-xl border border-border-gray p-3 sm:p-5 md:p-9 w-full sm:w-[90%] md:w-[540px]">
          <h1 className="text-xl md:text-2xl lg:text-[32px] text-[#333] text-center ">
            Password Reset Successful
          </h1>
          <p className="text-[#666] text-center text-sm md:text-base mt-2">
            {`You're all set to secure your account with your new credentials!`}
          </p>
          <img
            src={ResetPassword}
            alt="reset password"
            className="max-w-[301px] h-auto mx-auto mt-5"
          />
          <button className="button-primary w-full mt-7" type="submit">
            Continue
          </button>
        </div>
        {/* <div className="flex justify-center items-center mt-5 gap-x-5">
          <img
            src={PartnersLogo}
            alt="Penn"
            title="Penn"
            className="max-w-[87px] h-auto"
          />
          <img
            src={PartnersLogo}
            alt="Penn"
            title="Penn"
            className="max-w-[87px] h-auto"
          />
          <img
            src={PartnersLogo}
            alt="Penn"
            title="Penn"
            className="max-w-[87px] h-auto"
          />
          <img
            src={PartnersLogo}
            alt="Penn"
            title="Penn"
            className="max-w-[87px] h-auto"
          />
        </div>
        <p className="text-light-zusatzfarben text-sm uppercase mt-5  font-OpenSans-bold">
          Trusted by OVER 500 COMPANIES
        </p> */}
      </div>
    </section>
  );
};

export default ResetPasswordSuccessful;
