import {  Files, Link2,  X } from "lucide-react";
import PropTypes from "prop-types";

const GetPasswordLink = ({ onClose }) => {
  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div className="rounded-lg z-10 w-[94%] md:w-[460px] relative dark:bg-dark-black-300 bg-white ">
        <div className="py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200">
          <div className="flex justify-start items-center gap-x-4">
            <div
              className={`w-10 h-10 rounded  flex justify-center items-center  drop-shadow-4xl p-2  bg-lighter-zusatzfarben `}
            >
              <Link2 className="text-dark-zusatzfarben  " size={20} />
            </div>
          </div>

          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <div
          className={`py-4 px-4   dark:border-farbe  `}
        >
          <h2
            className={`text-lg  dark:text-dark-gray-350 text-center text-gray-900 `}
          >
            Get password link
          </h2>
          <p className="text-center text-gray-600 text-sm dark:text-white">
          Do you want to send the user a password rest mail?
          </p>
          <div className="mt-4 relative">
          <input type="text" className="w-full py-2 pl-12  pr-3 border border-gray-300 rounded bg-white outline-none focus:outline-none text-sm text-gray-400 placeholder:text-gray-400 " placeholder="https://user_com/" />
          <div className="absolute top-0 bottom-0 left-0 p-1 flex justify-center items-center rounded-l bg-dark-zusatzfarben w-10 ">
          <Files className="text-white" size={20} />
          </div>
          </div>
          
        </div>
      
      </div>
    </section>
  );
};

GetPasswordLink.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default GetPasswordLink;
