import { Building2, X } from "lucide-react";
import PropTypes from "prop-types";
import { useAxios } from "../../../../hooks/AxiosContext"; // Update the import path as needed
import { useEffect } from "react";
import { API_BASE_URL } from "../../../../utils/constants";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const EntitySchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  subdomain: Yup.string().required("URL path is required"),
  firstName: Yup.string().required("Owner user is required"),
  startDate: Yup.date()
    .required("Start date is required")
    .max(Yup.ref("endDate"), "Start date must be before end date"),

  endDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date must be after start date"),

  planId: Yup.string().required("Selecting a plan is required"),

  // Add more validation rules as needed
});
const EditEntity = ({ onClose, entity, refreshEntities }) => {
  const [plans, setPlans] = useState([]);
  const axiosInstance = useAxios();

  const { enqueueSnackbar } = useSnackbar();
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0]; // Ensures the date is handled in UTC
  };

  const formik = useFormik({
    initialValues: {
      companyName: entity ? entity.companyName : "",
      subdomain: entity ? entity.subdomain : "",
      firstName: entity ? entity.users[0]?.firstName || "" : "",
      startDate: entity ? formatDate(entity.subscriptions[0]?.startDate) : "",
      endDate: entity ? formatDate(entity.subscriptions[0]?.endDate) : "",
      planId: entity.subscriptions[0]?.planType || "",
      entityId: entity.entityId,

      // Initialize other fields here
    },
    validationSchema: EntitySchema,
    onSubmit: (values) => {
      console.log(values);
      // Handle form submission
      axiosInstance
        .post(`${API_BASE_URL}admin/entities/update`, values)
        .then((response) => {
          enqueueSnackbar("Entity and subscription updated successfully", {
            variant: "success",
          });
          // Handle success
          onClose(); // If you need to close the modal on success
          refreshEntities();
        })
        .catch((error) => {
          enqueueSnackbar("Error updateding in Entity", { variant: "error" });
        });
    },
  });

  useEffect(() => {
    // Fetch Stripe plans when the component mounts
    const fetchStripePlans = async () => {
      try {
        const response = await axiosInstance.get(
          `${API_BASE_URL}admin/stripe-plans`
        );
        setPlans(response.data.data);
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };

    fetchStripePlans();
  }, []);
  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div className="rounded-lg z-10 w-[94%] md:w-[640px] relative dark:bg-dark-black-300 bg-white ">
        <div className="py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200">
          <div className="flex justify-start items-center gap-x-4">
            <div
              className={`w-10 h-10 rounded  flex justify-center items-center  drop-shadow-4xl p-2 bg-lighter-zusatzfarben  `}
            >
              <Building2 className="text-dark-zusatzfarben  " size={20} />
            </div>
            <div>
              <h2 className={`text-lg  dark:text-dark-gray-350 text-gray-900 `}>
                Edit entity
              </h2>
              <p
                className={`text-sm   dark:text-gray-300 text-gray-600 
                `}
              >
                You can edit entity details here.
              </p>
            </div>
          </div>

          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <div className={`py-4  `}>
          <form onSubmit={formik.handleSubmit}>
            <div className="px-4 ">
              <div className="flex justify-between items-center w-full flex-wrap">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350   text-sm">
                  Name
                </label>
                <div className="w-full md:w-[60%] relative mt-2 md:mt-0">
                  {" "}
                  <input
                    name="companyName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                    type="text"
                    placeholder="e.g. Jenny "
                    className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 outline-none dark:text-gray-300 focus:outline-none border dark:border-farbe border-gray-300 bg-transparent rounded drop-shadow-4xl  w-full "
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="text-red-500 text-xs absolute -bottom-4 left-0">
                      {formik.errors.companyName}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-between items-center w-full mt-4 flex-wrap">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm">
                  Url path
                </label>
                <div className="w-full md:w-[60%] relative mt-2 md:mt-0">
                  {" "}
                  <input
                    name="subdomain"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subdomain}
                    type="text"
                    placeholder="codian.com"
                    className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 outline-none dark:text-gray-300  dark:border-farbe focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full"
                  />
                  {formik.touched.subdomain && formik.errors.subdomain ? (
                    <div className="text-red-500 text-xs absolute -bottom-4 left-0">
                      {formik.errors.subdomain}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-between items-center w-full mt-4 flex-wrap">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm">
                  Subscription type
                </label>
                <select
                  name="planId"
                  value={formik.values.planId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 outline-none dark:text-gray-300  dark:border-farbe focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full md:w-[60%] mt-2 md:mt-0 "
                >
                  <option value="" disabled>
                    Select a plan
                  </option>
                  {plans.map((plan) => (
                    <option
                      key={plan.id}
                      value={plan.id}
                      className="dark:text-gray-300"
                    >
                      {plan.name}
                    </option>
                  ))}
                </select>
                {formik.touched.planId && formik.errors.planId ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.planId}
                  </div>
                ) : null}
              </div>
              <div className="flex justify-between items-center w-full mt-4 flex-wrap">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm">
                  Current subscription
                </label>
                <div className="flex justify-between items-center w-full mt-2 md:mt-0 md:w-[60%]">
                  <div className="w-[48%] ">
                    <label className="text-gray-700 dark:text-dark-gray-350 text-sm">
                      Start date
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.startDate}
                      placeholder="e.g. Jenny "
                      className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 dark:text-gray-300  dark:border-farbe outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full "
                    />
                    {formik.touched.startDate && formik.errors.startDate ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.startDate}
                      </div>
                    ) : null}
                  </div>
                  <div className="w-[48%] ">
                    <label className="text-gray-700  dark:text-dark-gray-350 text-sm">
                      End date
                    </label>
                    <input
                      name="endDate"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.endDate}
                      type="date"
                      placeholder="e.g. Jenny "
                      className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 dark:text-gray-300  dark:border-farbe outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full "
                    />
                    {formik.touched.endDate && formik.errors.endDate ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.endDate}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center w-full mt-4 flex-wrap">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm">
                  Owner user
                </label>
                <input
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  type="text"
                  placeholder="Jenny Doe"
                  className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 outline-none dark:text-gray-300  dark:border-farbe focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full mt-2 md:mt-0 md:w-[60%]  "
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="  border-t dark:border-farbe border-gray-200 pb-4 mt-5 pt-6 w-full md:px-4 flex justify-center gap-x-5  py-4 px-4 ">
              <button type="button" className="button-cancel !w-[50%] h-11">
                Reset
              </button>
              <button type="submit" className="button-secondary !w-[50%] h-11">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

EditEntity.propTypes = {
  onClose: PropTypes.func.isRequired,
  entity: PropTypes.object,
  refreshEntities: PropTypes.func.isRequired,
};

export default EditEntity;
