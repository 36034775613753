import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAxios } from "../../../../hooks/AxiosContext";
import { useSnackbar } from "notistack";
import { API_BASE_URL } from "../../../../utils/constants";
import { ArrowLeft, Upload } from "lucide-react";
import ReactQuill, { Quill }  from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import ImageUploader from "quill-image-uploader";
import "react-quill/dist/quill.snow.css";
import AWS from "aws-sdk";
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import CreatableSelect from "react-select/creatable";
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/imageUploader", ImageUploader);

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{ color: ["#000","#063549", "#4B9E98", "#3F9891", "#d3edea99", "#D3EDEA"] }],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  },
  imageUploader: {
    upload: async (file) => {
      const link = await uploadFileS3(file);
      return link;
    }
  }
};

const uploadFileS3 = async (file) => {
  // S3 Bucket Name
  const S3_BUCKET = "hints-blog-assets";

  // S3 Region
  const REGION = "us-east-1";

  // S3 Credentials
  AWS.config.update({
    accessKeyId: "AKIAZI2LEM6S5F3JF57C",
    secretAccessKey: "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",
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  // Files Parameters

  const sanitizedFileName = file.name.replace(/\s/g, "_");
  const uniqueFileName = `${new Date().getTime()}_${sanitizedFileName}`;

  const params = {
    Bucket: S3_BUCKET,
    Key: uniqueFileName,
    Body: file,
  };

  // Uploading file to s3

  const ups3 = s3.upload(params).on("httpUploadProgress", evt => {
    console.log(
        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
    );
  }).promise();

  return ups3.then(res => {
    return res.Location;
  })
};

const formats = [
  'header', 'font', 'size', 'color',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

const EditBlog = ({ blog, onClose, onBlogUpdated }) => {
  const axiosInstance = useAxios();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [imagePreview, setImagePreview] = useState(blog.image);
  const [imageUrl, setImageUrl] = useState(blog.image);
  const [imagePreviewLeftSide, setImagePreviewLeftSide] = useState(blog.leftSide?.image);
  const [imageLink, setImageLink] = useState(blog.leftSide?.image);
  const [selectedHashtgs, setSelectedHashtags] = useState([])
  const [options, setOptions] = useState([]);
  const defaultValue = blog.hashtags?.map(item => ({value: item.hashtagId, label: item.hashtag})) || []
  useEffect(() => {
    axiosInstance
      .get(`${API_BASE_URL}admin/categories`)
      .then((response) => setCategories(response.data.categories))
      .catch((error) => {
        console.error("Error fetching categories:", error);
        enqueueSnackbar("Failed to fetch categories", { variant: "error" });
      });
    axiosInstance.get(`${API_BASE_URL}admin/hashtags`)
        .then(res => {
          const opts = res.data.hashtags.map(item => ({value: item.hashtagId, label: item.hashtag}));
          console.log(opts);
          setOptions(opts);
        }).catch(error => {
      console.error("Error fetching categories:", error);
      enqueueSnackbar("Failed to fetch categories", { variant: "error" });
    })
  }, [axiosInstance, enqueueSnackbar]);

  const getValidationSchema = () => {
    return Yup.object({
      titleEn: Yup.string().required("Title in English is required"),
      titleDe: Yup.string().required("Title in German is required"),
      categoryId: Yup.string().required("Category is required"),
      descriptionEn: Yup.string().required("English description is required"),
      descriptionDe: Yup.string().required("German description is required"),
      image: Yup.mixed().required("An image is required"),
      metaTitleEn: Yup.string().required("Meta Title in English is required"),
      metaTitleDe: Yup.string().required("Meta Title in German is required"),
      metaDescriptionEn: Yup.string().required("English meta description is required"),
      metaDescriptionDe: Yup.string().required("German meta description is required"),
      url: Yup.string().required("Url is required")
          .matches(/^([\w]+[1-9]*-*)+$/,  "URL must have only alphabets, numbers and upperscore")
          .matches(/[a-zA-Z0-9]$/, "URL must ends with an alphanumeric")
          .min(4, 'URL must have at least 4 characters'),
      useLeftSideLink: Yup.bool(),
      leftSide: Yup.object({
        titleEn: Yup.string().when('useLeftSideLink', {
          is: true,
          then: schema => schema.required('Title in English is required'),
        }),
        titleDe: Yup.string().when('useLeftSideLink', {
          is: true,
          then: schema => schema.required('Title in German is required'),
        }),
        descriptionEn: Yup.string().when('useLeftSideLink', {
          is: true,
          then: schema => schema.required('English description is requiredd'),
        }),
        descriptionDe: Yup.string().when('useLeftSideLink', {
          is: true,
          then: schema => schema.required('German description is required'),
        }),
        image: Yup.mixed().when('useLeftSideLink', {
          is: true,
          then: schema => schema.required('Image is required'),
          otherwise: schema => schema.nullable()
        }),
        link: Yup.string().when('useLeftSideLink', {
          is: true,
          then: schema => schema.required('Link is required')
              .matches(/^([\w]+[1-9]*-*)+$/,  "URL must have only alphabets, numbers and upperscore")
              .matches(/[a-zA-Z0-9]$/, "URL must ends with an alphanumeric")
              .min(4, 'URL must have at least 4 characters'),
        })
      })
    });
  };

  const formik = useFormik({
    initialValues: {
      titleEn: blog.titleEn,
      titleDe: blog.titleDe,
      categoryId: blog.categoryId.toString(),
      descriptionEn: blog.descriptionEn,
      descriptionDe: blog.descriptionDe,
      image: blog.image,
      metaTitleEn: blog.metaTitleEn,
      metaTitleDe: blog.metaTitleDe,
      metaDescriptionEn: blog.metaDescriptionEn,
      metaDescriptionDe: blog.metaDescriptionDe,
      url: blog.slugEn,
      useLeftSideLink: typeof blog.leftSideId === 'number',
      leftSide: blog.leftSideId ?blog.leftSide : {
        titleEn: "",
        titleDe: "",
        descriptionEn: "",
        descriptionDe: "",
        image: null,
        link: '',
      }
    },
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      setIsSubmitting(true);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === "image") {
          formData.append(key, imageUrl);
        } else {
          formData.append(key, values[key]);
        }
      });

      const data = {
        ...values,
        image: imageUrl,
        leftSideId: blog.leftSideId,
        hashtags: selectedHashtgs
      }
      if(values.useLeftSideLink) {
        data.leftSide = {
          ...values.leftSide,
          image: imageLink,
        }
      } else {
        data.leftSide = null;
        data.leftSideId = null;
      }

      axiosInstance
        .put(`${API_BASE_URL}admin/update-blog/${blog.blogId}`, data)
        .then(() => {
          enqueueSnackbar("Blog updated successfully", { variant: "success" });
          onClose();
          onBlogUpdated();
        })
        .catch((error) => {
          console.error("Error updating blog:", error);
          enqueueSnackbar("Failed to update blog" + `${error.response.data.error ? ": "+error?.response?.data?.error:""}`, { variant: "error" });
        })
        .finally(() => setIsSubmitting(false));
    },
  });

  const handleImageChange = async (event, left = false) => {
    const file = event.target.files[0];
    if (file) {
      if (left) {
        formik.setFieldValue("leftSide.image", file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewLeftSide(reader.result);
        };
        reader.readAsDataURL(file);
        const link = await uploadFileS3(file);
        setImageLink(link);
      } else {
        formik.setFieldValue("image", file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
        const link = await uploadFileS3(file);
        setImageUrl(link);
      }
    }
  };

  return (
    <>
      <section className="w-full h-screen pt-16 overflow-hidden overflow-y-auto bg-white lg:pt-20 dark:bg-dark-black-300">
        <div className="flex items-center justify-between px-5 lg:px-10 py-8 ">
          <h2 className="text-2xl dark:text-dark-gray-350 text-farbe">
            Add Blog
          </h2>
          <button className="button-secondary " type="button" onClick={onClose}>
            <ArrowLeft className="mr-2 text-white" size={20} /> Back
          </button>
        </div>
        <div className="px-5 lg:px-10">
          <form onSubmit={formik.handleSubmit}>
            <div className={`pt-4 lg:px-4  `}>
              {/* Title EN */}
              <div className="flex flex-wrap items-center justify-between w-full ">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Title EN
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <input
                      type="text"
                      name="titleEn"
                      placeholder="Title EN"
                      onChange={formik.handleChange}
                      value={formik.values.titleEn}
                      className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:text-gray-300 dark:placeholder:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                  />
                  {formik.touched.titleEn && formik.errors.titleEn && (
                      <div className="text-sm text-red-500">
                        {formik.errors.titleEn}
                      </div>
                  )}
                </div>
              </div>

              {/* Title DE */}
              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Title DE
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <input
                      type="text"
                      name="titleDe"
                      placeholder="Title DE"
                      onChange={formik.handleChange}
                      value={formik.values.titleDe}
                      className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                  />
                  {formik.touched.titleDe && formik.errors.titleDe && (
                      <div className="text-sm text-red-500">
                        {formik.errors.titleDe}
                      </div>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Meta Title EN
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <input
                      type="text"
                      name="metaTitleEn"
                      placeholder="Meta Title EN"
                      onChange={formik.handleChange}
                      value={formik.values.metaTitleEn}
                      className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                  />
                  {formik.touched.metaTitleEn && formik.errors.metaTitleEn && (
                      <div className="text-sm text-red-500">
                        {formik.errors.metaTitleEn}
                      </div>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Meta Title DE
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <input
                      type="text"
                      name="metaTitleDe"
                      placeholder="Meta Title DE"
                      onChange={formik.handleChange}
                      value={formik.values.metaTitleDe}
                      className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                  />
                  {formik.touched.metaTitleDe && formik.errors.metaTitleDe && (
                      <div className="text-sm text-red-500">
                        {formik.errors.metaTitleDe}
                      </div>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Meta Description EN
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <input
                      type="text"
                      name="metaDescriptionEn"
                      placeholder="Meta Description EN"
                      onChange={formik.handleChange}
                      value={formik.values.metaDescriptionEn}
                      className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                  />
                  {formik.touched.metaDescriptionEn && formik.errors.metaDescriptionEn && (
                      <div className="text-sm text-red-500">
                        {formik.errors.metaDescriptionEn}
                      </div>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Meta Description DE
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <input
                      type="text"
                      name="metaDescriptionDe"
                      placeholder="Meta Description DE"
                      onChange={formik.handleChange}
                      value={formik.values.metaDescriptionDe}
                      className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                  />
                  {formik.touched.metaTitleDe && formik.errors.metaDescriptionDe && (
                      <div className="text-sm text-red-500">
                        {formik.errors.metaDescriptionDe}
                      </div>
                  )}
                </div>
              </div>

              {/* Category */}
              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Category
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <select
                      name="categoryId"
                      onChange={formik.handleChange}
                      value={formik.values.categoryId}
                      className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                  >
                    <option value="">Choose a category</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.categoryId}>
                          {category.nameEn}{" "}
                          {/* Adjust according to your data structure */}
                        </option>
                    ))}
                  </select>
                  {formik.touched.categoryId && formik.errors.categoryId && (
                      <div className="text-sm text-red-500">
                        {formik.errors.categoryId}
                      </div>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  URL <small>({"lang"} refers to the language)</small>
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <div className="w-full mt-2 sm:mt-0 sm:w-[50%] md:w-[100%] flex justify-start flex-wrap relative">
                    <input
                        type=""
                        disabled="disabled"
                        className={`border-l border-t border-b border-r md:border-r-0 rounded-l rounded-r md:rounded-r-none  drop-shadow-4xl py-2 px-3 w-full md:w-[65%] text-sm md:text-base outline-none bg-white border-gray-300 placeholder:text-dark-zusatzfarben text-dark-zusatzfarben`}
                        placeholder={"https://lp-hints.vercel.app/{lang}/blog/"}
                    />
                    <input
                        type="text"
                        name="url"
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border  rounded-r rounded-l md:rounded-l-none outline-none mt-2 md:mt-0 drop-shadow-4xl py-2 px-3 w-full md:w-[35%] text-sm md:text-base placeholder:text-gray-500 bg-white border-gray-300`}
                        placeholder="Blog's url"
                    />
                    {formik.touched.url && formik.errors.url && (
                        <div className="text-xs text-red-500 ">
                          {formik.errors.url}
                        </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Picture */}
              {/* Picture */}
              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Picture
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <input
                      type="file"
                      name="image"
                      onChange={(event) => {
                        handleImageChange(event);
                      }}
                      className="absolute top-0 left-0 z-30 w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none opacity-0 md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                  />
                  <div
                      className="relative flex items-center justify-center w-full px-5 py-3 text-sm bg-transparent border border-gray-300 rounded gap-x-2 text-dark-zusatzfarben md:text-base dark:border-farbe focus:outline-none drop-shadow-4xl ">
                    <Upload size={20}/> Upload a image
                    {imagePreview && (
                        <div className="absolute top-1/2 -translate-y-1/2 left-[10%]">
                          <img
                              src={imagePreview}
                              alt="Preview"
                              className="rounded-full w-9 h-9"
                          />
                        </div>
                    )}
                  </div>
                  {formik.touched.image && formik.errors.image && (
                      <div className="mt-2 text-sm text-red-500">
                        {formik.errors.image}
                      </div>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Hashtags
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <CreatableSelect isMulti options={options} defaultValue={defaultValue} onChange={v => {
                    const h = v.map(item => ({hashtagId: isNaN(item.value) ? null : item.value, hashtag: item.label}));
                    setSelectedHashtags(h);
                  }}/>
                </div>
              </div>


              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                  Use Left Side Link
                </label>
                <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                  <input
                      type="checkbox"
                      name="useLeftSideLink"
                      onChange={formik.handleChange}
                      checked={formik.values.useLeftSideLink}
                      className=""
                  />
                  {formik.touched.useLeftSideLink && formik.errors.useLeftSideLink && (
                      <div className="text-sm text-red-500">
                        {formik.errors.useLeftSideLink}
                      </div>
                  )}
                </div>
              </div>

              {formik.values.useLeftSideLink &&
                  <div className={"p-7"}>
                    {/* Title EN */}
                    <div className="flex flex-wrap items-center justify-between w-full ">
                      <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                        Title EN
                      </label>
                      <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                        <input
                            type="text"
                            name="leftSide.titleEn"
                            placeholder="Title EN"
                            onChange={formik.handleChange}
                            value={formik.values.leftSide.titleEn}
                            className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:text-gray-300 dark:placeholder:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                        />
                        {formik.touched.leftSide?.titleEn && formik.errors.leftSide?.titleEn && (
                            <div className="text-sm text-red-500">
                              {formik.errors.leftSide?.titleEn}
                            </div>
                        )}
                      </div>
                    </div>

                    {/* Title DE */}
                    <div className="flex flex-wrap items-center justify-between w-full mt-6">
                      <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                        Title DE
                      </label>
                      <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                        <input
                            type="text"
                            name="leftSide.titleDe"
                            placeholder="Title DE"
                            onChange={formik.handleChange}
                            value={formik.values.leftSide?.titleDe}
                            className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                        />
                        {formik.touched.leftSide?.titleDe && formik.errors.leftSide?.titleDe && (
                            <div className="text-sm text-red-500">
                              {formik.errors.leftSide?.titleDe}
                            </div>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap items-center justify-between w-full mt-6">
                      <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                        Description EN
                      </label>
                      <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                        <input
                            type="text"
                            name="leftSide.descriptionEn"
                            placeholder="Description EN"
                            onChange={formik.handleChange}
                            value={formik.values.leftSide?.descriptionEn}
                            className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                        />
                        {formik.touched.leftSide?.descriptionEn && formik.errors.leftSide?.descriptionEn && (
                            <div className="text-sm text-red-500">
                              {formik.errors.leftSide?.descriptionEn}
                            </div>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap items-center justify-between w-full mt-6">
                      <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                        Description DE
                      </label>
                      <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                        <input
                            type="text"
                            name="leftSide.descriptionDe"
                            placeholder="Description DE"
                            onChange={formik.handleChange}
                            value={formik.values.leftSide?.descriptionDe}
                            className="w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                        />
                        {formik.touched.leftSide?.descriptionDe && formik.errors.leftSide?.descriptionDe && (
                            <div className="text-sm text-red-500">
                              {formik.errors.leftSide?.descriptionDe}
                            </div>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap items-center justify-between w-full mt-6">
                      <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                        URL <small>({"lang"} refers to the language)</small>
                      </label>
                      <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                        <div
                            className="w-full mt-2 sm:mt-0 sm:w-[50%] md:w-[100%] flex justify-start flex-wrap relative">
                          <input
                              type=""
                              disabled="disabled"
                              className={`border-l border-t border-b border-r md:border-r-0 rounded-l rounded-r md:rounded-r-none  drop-shadow-4xl py-2 px-3 w-full md:w-[65%] text-sm md:text-base outline-none bg-white border-gray-300 placeholder:text-dark-zusatzfarben text-dark-zusatzfarben`}
                              placeholder={"https://lp-hints.vercel.app/{lang}/"}
                          />
                          <input
                              type="text"
                              name="leftSide.link"
                              value={formik.values.leftSide.link}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={`border  rounded-r rounded-l md:rounded-l-none outline-none mt-2 md:mt-0 drop-shadow-4xl py-2 px-3 w-full md:w-[35%] text-sm md:text-base placeholder:text-gray-500 bg-white border-gray-300`}
                              placeholder="Blog's url"
                          />
                          {formik.touched.leftSide?.link && formik.errors.leftSide?.link && (
                              <div className="text-xs text-red-500 ">
                                {formik.errors.leftSide?.link}
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Picture */}
                    {/* Picture */}
                    <div className="flex flex-wrap items-center justify-between w-full mt-6">
                      <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                        Picture
                      </label>
                      <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                        <input
                            type="file"
                            name="leftSide.image"
                            onChange={(event) => {
                              handleImageChange(event, true);
                            }}
                            className="absolute top-0 left-0 z-30 w-full px-3 py-2 text-sm text-gray-700 bg-transparent border border-gray-300 rounded outline-none opacity-0 md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe focus:outline-none drop-shadow-4xl "
                        />
                        <div
                            className="relative flex items-center justify-center w-full px-5 py-3 text-sm bg-transparent border border-gray-300 rounded gap-x-2 text-dark-zusatzfarben md:text-base dark:border-farbe focus:outline-none drop-shadow-4xl">
                          <Upload size={20}/> Upload a image
                          {imagePreviewLeftSide && (
                              <div className="absolute top-1/2 -translate-y-1/2 left-[10%]">
                                <img
                                    src={imagePreviewLeftSide}
                                    alt="Preview"
                                    className="rounded-full w-9 h-9"
                                />
                              </div>
                          )}
                        </div>
                        {formik.touched.leftSide?.image && formik.errors.leftSide?.image && (
                            <div className="mt-2 text-sm text-red-500">
                              {formik.errors.leftSide?.image}
                            </div>
                        )}
                      </div>
                    </div>
                  </div>}

              {/* Description EN */}
              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full text-sm text-gray-700 dark:text-dark-gray-350 md:text-base">
                  Description EN
                </label>
                <div className="relative w-full mt-2">
                  <ReactQuill
                      theme="snow"
                      value={formik.values.descriptionEn}
                      onChange={(content) =>
                          formik.setFieldValue("descriptionEn", content)
                      }
                      placeholder="Enter your blog content here"
                      className="quill-editor"
                      formats={formats}
                      modules={modules}
                  />
                  {formik.touched.descriptionEn &&
                      formik.errors.descriptionEn && (
                          <div className="text-sm text-red-500">
                            {formik.errors.descriptionEn}
                          </div>
                      )}
                </div>
              </div>

              {/* Description DE */}
              <div className="flex flex-wrap items-center justify-between w-full mt-6">
                <label className="w-full text-sm text-gray-700 dark:text-dark-gray-350 md:text-base">
                  Description DE
                </label>
                <div className="relative w-full mt-2">
                  <ReactQuill
                      theme="snow"
                      value={formik.values.descriptionDe}
                      onChange={(content) =>
                          formik.setFieldValue("descriptionDe", content)
                      }
                      placeholder="Enter your blog content here"
                      className="quill-editor"
                      formats={formats}
                      modules={modules}
                  />
                  {formik.touched.descriptionDe &&
                      formik.errors.descriptionDe && (
                          <div className="text-sm text-red-500">
                            {formik.errors.descriptionDe}
                          </div>
                      )}
                </div>
              </div>
            </div>
            <div
                className="flex justify-center w-full px-4 pt-6 pb-4 mt-5 border-t border-gray-200 dark:border-farbe md:px-4 gap-x-5">
              <button type="button" className="button-cancel !w-[50%] h-11">
                Reset
              </button>

              <button
                  disabled={isSubmitting}
                  type="submit"
                  className="button-secondary !w-[50%] h-11 "
              >
                {isSubmitting ? (
                    <div className="three-body-loading">
                      <div className="three-body-loading__dot"></div>
                      <div className="three-body-loading__dot"></div>
                      <div className="three-body-loading__dot"></div>
                    </div>
                ) : (
                    <span> Update</span>
                )}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

EditBlog.propTypes = {
  blog: PropTypes.object.isRequired, // Adjust according to your actual data structure
  onClose: PropTypes.func.isRequired,
  onBlogUpdated: PropTypes.func,
};
export default EditBlog;
