import {
  ChevronLeft,
  ChevronRight,
  CornerUpLeft,
  Plus,
  Trash2,
} from "lucide-react";
import { useState } from "react";
import AddNewTicket from "./addNewTicket";
import DeleteResolutionHub from "./deleteResultionHub";
import EditTicket from "./editTicket";

const ResolutionHub = () => {
  const [openResolutionHub, setOpenResolutionHub] = useState(false);
  const [openDeleteTicket, setOpenDeleteTicket] = useState(false);
  const [openEditTicket, setOpenEditTicket] = useState(false);

  // add new ticket
  const handleAddResolutionHub = () => {
    setOpenResolutionHub(true);
  };

  const handleCloseResolutionHub = () => {
    setOpenResolutionHub(false);
  };

  // delete ticket
  const handleDeleteTicket = () => {
    setOpenDeleteTicket(true);
  };

  const handleCloseDeleteTicket = () => {
    setOpenDeleteTicket(false);
  };

  //edit ticket
  const handleEditTicket = () => {
    setOpenEditTicket(true);
  };

  const handleCloseEditTicket = () => {
    setOpenEditTicket(false);
  };

  return (
    <>
      {openEditTicket ? (
        <EditTicket onClose={handleCloseEditTicket} />
      ) : (
        <section className="pt-16 lg:pt-20 w-full h-screen overflow-y-auto dark:bg-dark-black-300 bg-white">
          <div className="py-5 lg:py-8 px-5 lg:px-10 flex justify-between items-center">
            <h2 className="text-xl md:text-2xl  dark:text-dark-gray-350 text-farbe">
              Resolution Hub
            </h2>
            <button
              className="button-secondary "
              type="button"
              onClick={() => handleAddResolutionHub()}
            >
              <Plus className="text-white mr-2" size={20} /> Add
            </button>
          </div>
          <div className="px-5 mt-5 w-full">
            <table className="w-full responsive-table ">
              <thead>
                <tr>
                  <th className="text-farbe dark:text-dark-gray-350 text-base px-5 py-3 text-left">
                    Ticket Id
                  </th>
                  <th className="text-farbe dark:text-dark-gray-350 text-base px-5 py-3 text-left">
                    Name
                  </th>
                  <th className="text-farbe dark:text-dark-gray-350 text-base px-5 py-3 text-left">
                    Email
                  </th>
                  <th className="text-farbe dark:text-dark-gray-350 text-base px-5 py-3 text-left">
                    Subject
                  </th>
                  <th className="text-farbe dark:text-dark-gray-350 text-base px-5 py-3 text-left">
                    Status
                  </th>
                  <th className="text-farbe dark:text-dark-gray-350 text-base px-5 py-3 text-left">
                    Created at
                  </th>{" "}
                  <th className="text-farbe dark:text-dark-gray-350 text-base px-5 py-3 text-left">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="hover:bg-lighter-white lg:border-b dark:border-farbe border-light-zusatzfarben last:border-0 dark:bg-dark-blue-950 bg-gray-100 lg:bg-transparent mb-4 lg:mb-0 rounded lg:rounded-none lg:dark:bg-transparent ">
                  <td className="text-light-black dark:text-gray-300 dark:after:text-gray-300 after:text-black  text-sm px-5 py-3 text-left" title="Ticket Id">
                    <div className="border border-dark-zusatzfarben text-sm p-[2px] text-dark-zusatzfarben max-w-[80px] w-full rounded flex justify-center items-center">
                      1689670
                    </div>
                  </td>
                  <td className="text-light-black dark:text-gray-300 dark:after:text-gray-300 after:text-black  text-sm px-5 py-3 text-left" title=" Name">
                    John Carlo
                  </td>
                  <td className="text-light-black dark:text-gray-300 dark:after:text-gray-300 after:text-black  text-sm px-5 py-3 text-left" title="Email">
                    admin@examle.com
                  </td>
                  <td className="text-light-black dark:text-gray-300 dark:after:text-gray-300 after:text-black  text-sm px-5 py-3 text-left" title=" Subject">
                    Event support
                  </td>
                  <td className="text-light-black dark:text-gray-300 dark:after:text-gray-300 after:text-black  text-sm px-5 py-3 text-left" title=" Status">
                    <div className="border border-[#FF9E55] text-sm p-[2px] rounded-xl bg-orange-50  max-w-[100px]  w-full text-[#FF9E55] flex justify-center items-center">
                      In Progress
                    </div>
                  </td>
                  <td className="text-light-black dark:text-gray-300 dark:after:text-gray-300 after:text-black  text-sm px-5 py-3 text-left" title="Created at">
                    26 Dec, 2023 12:30 AM
                  </td>
                  <td className="text-light-black dark:text-gray-300 dark:after:text-gray-300 after:text-black  text-sm px-5 py-3 text-left" title="Action">
                    <ul className="flex justify-start items-center gap-x-3">
                      <li className="text-farbe dark:text-gray-300 hover:text-dark-zusatzfarben cursor-pointer">
                        <CornerUpLeft
                          size={18}
                          onClick={() => handleEditTicket()}
                        />
                      </li>
                      <li className="text-farbe dark:text-gray-300 hover:text-redish cursor-pointer">
                        <Trash2
                          size={18}
                          onClick={() => handleDeleteTicket()}
                        />
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="border-t dark:border-farbe border-[#D3EDEA] mt-10 py-3 md:px-5  flex justify-between items-center">
              <button type="button" className="button-secondary !w-[84px]">
                <ChevronLeft className="text-white mr-2" size={20} /> Prev
              </button>
              <p className="text-sm text-black dark:text-gray-300 ">
                Page{" "}
                <span className="text-farbe dark:text-dark-gray-350 font-OpenSans-ExtraBold">1</span> of
                10
              </p>
              <button type="button" className="button-secondary !w-[84px]">
                Next <ChevronRight className="text-white ml-2" size={20} />
              </button>
            </div>
          </div>
        </section>
      )}
      {openResolutionHub && <AddNewTicket onClose={handleCloseResolutionHub} />}
      {openDeleteTicket && (
        <DeleteResolutionHub onClose={handleCloseDeleteTicket} />
      )}
    </>
  );
};

export default ResolutionHub;
