import { ChevronLeft, ChevronRight, Trash2, PenLine, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import AddBlog from "./addBlog";
import EditBlog from "./editBlog";
import { useAxios } from "../../../../hooks/AxiosContext";
import { API_BASE_URL } from "../../../../utils/constants";
import DeleteBlog from "./deleteBlog";
import {useNavigate} from "react-router-dom";
const BlogArticle = () => {
  const [openAddBlog, setOpenAddBlog] = useState(false);
  const [openEditBlog, setOpenEditBlog] = useState(false);
  const axiosInstance = useAxios();
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [editingBlog, setEditingBlog] = useState(null);
  const [deleteBlog, setDeleteBlog] = useState(null);
  const navigate = useNavigate();

  const [openDeleteBlog, setOpenDeleteBlog] = useState(false);
  useEffect(() => {
    fetchBlogs();
  }, [currentPage]);

  const fetchBlogs = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_BASE_URL}admin/blogs?page=${currentPage}&limit=10`
      );
      setBlogs(response.data.blogs);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      // Handle error
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleOpenAddBlog = () => {
    navigate("/app/blogs/add-new")
    setOpenAddBlog(true);
    setOpenEditBlog(false);
    setOpenDeleteBlog(false); // Ensure this modal is closed when opening another
  };

  const handleOpenEditBlog = (blog) => {
    setEditingBlog(blog); // Save the entire blog object to be edited
    setOpenEditBlog(true);
    setOpenAddBlog(false);
    setOpenDeleteBlog(false); // Ensure this modal is closed when opening another
  };

  const handleDeleteBlog = (blog) => {
    setDeleteBlog(blog); // Identify which blog to delete
    setOpenDeleteBlog(true); // Open the delete confirmation modal
  };
  const onDeleteSuccess = () => {
    setOpenDeleteBlog(false); // Close the delete confirmation modal
    refreshBlogs(); // Refresh the list of blogs
  };

  const refreshBlogs = () => {
    fetchBlogs(); // Assuming fetchBlogs is already fetching the blogs list
  };
  return (
    <>
      {openAddBlog ? (
        <AddBlog
          onClose={() => setOpenAddBlog(false)}
          onBlogAdded={refreshBlogs}
        />
      ) : openEditBlog ? (
        <EditBlog
          blog={editingBlog}
          onClose={() => {
            setOpenEditBlog(false);
            refreshBlogs(); // Refresh the list after editing
          }}
          onBlogUpdated={refreshBlogs}
        />
      ) : openDeleteBlog ? (
        <DeleteBlog
          blog={deleteBlog}
          onClose={() => setOpenDeleteBlog(false)} // This just closes the modal
          onDelete={onDeleteSuccess} // This refreshes the blog list
        />
      ) : (
        <section className="w-full h-screen pt-16 overflow-y-auto bg-white lg:pt-20 dark:bg-dark-black-300">
          <div className="flex items-center justify-between  py-8 px-5 lg:px-10">
            <h2 className="text-2xl dark:text-dark-gray-350 text-farbe">
              Blogs Article
            </h2>
            <button
              className="button-secondary "
              type="button"
              onClick={() => handleOpenAddBlog()}
            >
              <Plus className="mr-2 text-white" size={20} /> Add
            </button>
          </div>
          <div className="px-5 lg:px-10">
            <table className="w-full responsive-table ">
              <thead>
                <tr>
                  <th className="px-5 py-3 text-base text-left text-farbe dark:text-dark-gray-350 font-OpenSans-bold">
                    Category
                  </th>
                  <th className="px-5 py-3 text-base text-left text-farbe dark:text-dark-gray-350 font-OpenSans-bold">
                    Title
                  </th>
                  <th className="px-5 py-3 text-base text-left text-farbe dark:text-dark-gray-350 font-OpenSans-bold">
                    Image
                  </th>
                  <th className="px-5 py-3 text-base text-left text-farbe dark:text-dark-gray-350 font-OpenSans-bold">
                    Description
                  </th>
                  <th className="px-5 py-3 text-base text-left text-farbe dark:text-dark-gray-350 font-OpenSans-bold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {blogs.map((blog) => (
                  <tr
                    key={blog.blogId}
                    className="mb-4 bg-gray-100 rounded hover:bg-lighter-white lg:border-b dark:border-farbe border-light-zusatzfarben last:border-0 dark:bg-dark-blue-950 lg:bg-transparent lg:mb-0 lg:rounded-none lg:dark:bg-transparent"
                  >
                    <td
                      className="px-5 py-3 text-sm text-left text-light-black dark:text-gray-300 font-OpenSans-bold dark:after:text-gray-300 after:text-black"
                      title="Category"
                    >
                      {blog.categoryId}
                    </td>
                    <td
                      className="px-5 py-3 text-sm text-left text-light-black dark:text-gray-300 font-OpenSans-bold dark:after:text-gray-300 after:text-black"
                      title="Title"
                    >
                      {blog.titleEn}
                    </td>
                    <td
                      className="px-5 py-3 text-sm text-left text-light-black dark:text-gray-300 font-OpenSans-bold dark:after:text-gray-300 after:text-black"
                      title="Image"
                    >
                      <div className="flex justify-center border border-gray-200 rounded-full w-11 h-11 item-center dark:border-farbe">
                        <img
                          src={blog.image}
                          alt="blog"
                          className="border border-gray-200 rounded-full w-11 h-11 dark:border-farbe"
                        />
                      </div>
                    </td>
                    <td
                      className="px-5 py-3 text-sm text-left text-light-black dark:text-gray-300 font-OpenSans-bold dark:after:text-gray-300 after:text-black"
                      title="Description"
                      dangerouslySetInnerHTML={{ __html: blog.descriptionEn }}
                    />
                    <td
                      className="px-5 py-3 text-sm text-left text-light-black dark:text-gray-300 font-OpenSans-bold dark:after:text-gray-300 after:text-black"
                      title="Actions"
                    >
                      <ul className="flex items-center justify-start gap-x-3">
                        <li>
                          <PenLine
                            onClick={() => handleOpenEditBlog(blog)}
                            className="cursor-pointer text-farbe dark:text-gray-300 hover:text-blue-500 "
                            size={18}
                          />
                        </li>
                        <li>
                          <Trash2
                            onClick={() => handleDeleteBlog(blog)}
                            className="text-farbe dark:text-gray-300 cursor-pointer hover:text-dark-zusatzfarben "
                            size={18}
                          />
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="border-t border-[#D3EDEA] dark:border-farbe mt-10 py-3 px-10 flex justify-between items-center">
            <button
              type="button"
              className="button-secondary !w-[84px]"
              onClick={handlePreviousPage}
              disabled={currentPage <= 1}
            >
              <ChevronLeft className="mr-2 text-white" size={20} /> Prev
            </button>
            <p className="text-sm text-black dark:text-gray-300">
              Page{" "}
              <span className="text-farbe dark:text-dark-gray-350 font-OpenSans-ExtraBold">
                {currentPage}
              </span>{" "}
              of {totalPages}
            </p>
            <button
              type="button"
              className="button-secondary !w-[84px]"
              onClick={handleNextPage}
              disabled={currentPage >= totalPages}
            >
              Next <ChevronRight className="ml-2 text-white" size={20} />
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogArticle;
