import { LogOut, Moon, Sun } from "lucide-react";
import UserImg from "../../../assets/sidebar/user-riona.png";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import useDarkMode from "../../../hooks/useDarkMode";
import { Link } from "react-router-dom";

const MobileMenu = ({ handleCloseMobileMenu }) => {
  const [colorTheme, setTheme] = useDarkMode();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };

  return (
    <>
      <div className="absolute inset-0 bg-gray-800 opacity-70 z-30 md:hidden  "></div>
      <section className="absolute top-0 left-0 bottom-0 min-h-screen h-screen sm:w-[364px] w-[80%] bg-farbe z-50 py-5 md:hidden">
        <div className="border-b w-full pb-3 px-4 border-dark-blue-950  flex justify-start items-center relative gap-x-4">
          <img
            src={UserImg}
            alt=""
            title=""
            className="w-12 h-12 rounded-full border border-dark-blue-950 p-[2px]"
          />
          <div>
            <h1 className={`text-sm text-dark-gray-350 `}> Riona </h1>
            <p className={`text-xs text-gray-200 `}>93riona@gmail.com</p>
          </div>
          <LogOut className="text-dark-zusatzfarben absolute top-1 right-5" />
        </div>
        <ul className="w-full ">
          <li className="border-b pt-3 pb-3 border-dark-blue-950 text-base px-4 font-OpenSans-bold text-dark-gray-350  hover:text-dark-zusatzfarben">
            <Link
              to={`/app/dashboard`}
              className="pt-3 pb-3 px-4 w-full"
              onClick={handleCloseMobileMenu}
            >
              {" "}
              Dashboard
            </Link>
          </li>
          <li className="border-b pt-3 pb-3 border-dark-blue-950 text-base px-4 font-OpenSans-bold text-dark-gray-350  hover:text-dark-zusatzfarben">
            <Link
              to={`/app/central-management`}
              className="pt-3 pb-3 px-4 w-full"
              onClick={handleCloseMobileMenu}
            >
              {" "}
              Central Management
            </Link>
          </li>
          <li className="border-b pt-3 pb-3 border-dark-blue-950 text-base px-4 font-OpenSans-bold text-dark-gray-350  hover:text-dark-zusatzfarben">
            <Link
              to={`/app/blogs/category`}
              className="pt-3 pb-3 px-4 w-full"
              onClick={handleCloseMobileMenu}
            >
              {" "}
              Category
            </Link>
          </li>
          <li className="border-b pt-3 pb-3 border-dark-blue-950 text-base px-4 font-OpenSans-bold text-dark-gray-350  hover:text-dark-zusatzfarben">
            <Link
              to={`/app/blogs/blog-article`}
              className="pt-3 pb-3 px-4 w-full"
              onClick={handleCloseMobileMenu}
            >
              {" "}
              Blog Article
            </Link>
          </li>
          <li className="border-b pt-3 pb-3 border-dark-blue-950 text-base px-4 font-OpenSans-bold text-dark-gray-350  hover:text-dark-zusatzfarben">
            <Link
              to={`/app/roles-and-permission`}
              className="pt-3 pb-3 px-4 w-full"
              onClick={handleCloseMobileMenu}
            >
              {" "}
              Roles And Permission
            </Link>
          </li>
          <li className="border-b pt-3 pb-3 border-dark-blue-950 text-base px-4 font-OpenSans-bold text-dark-gray-350  hover:text-dark-zusatzfarben">
            <Link
              to={`/app/activity-tracking`}
              className="pt-3 pb-3 px-4 w-full"
              onClick={handleCloseMobileMenu}
            >
              {" "}
              Activity Tracking
            </Link>
          </li>
          {/* <li className="border-b pt-3 pb-3 border-dark-blue-950 text-base px-4 font-OpenSans-bold text-dark-gray-350  hover:text-dark-zusatzfarben">
            <Link
              to={`/app/resolution-hub`}
              className="pt-3 pb-3 px-4 w-full"
              onClick={handleCloseMobileMenu}
            >
              {" "}
              Resolution Hub
            </Link>
          </li> */}
        </ul>
        <div
          className={`fixed left-0   bottom-3  flex justify-center items-center gap-x-3 toogle-dark `}
        >
          <div className=" p-4 relative flex justify-center items-center rounded-lg">
            <Switch
              checked={darkSide}
              onChange={toggleDarkMode}
              className={` relative inline-flex h-7 w-14 items-center outline-none rounded-full bg-lighter-white`}
            >
              <span
                className={`${
                  darkSide ? "translate-x-7" : "translate-x-1"
                }  h-6 w-6 transform rounded-full bg-white transition flex justify-center items-center`}
              >
                {darkSide ? (
                  <Moon className="text-farbe" size={18} />
                ) : (
                  <Sun className="text-farbe" size={18} />
                )}
              </span>
            </Switch>
          </div>
          <h4 className={`text-dark-gray-350  whitespace-nowrap `}>
            {darkSide ? "Dark mode " : " Light mode"}
          </h4>
        </div>
      </section>
    </>
  );
};

export default MobileMenu;
