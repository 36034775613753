import { X } from "lucide-react";
import PropTypes from "prop-types";
import { FaStripe } from "react-icons/fa";

const OpenStripeAccount = ({ onClose, stripeSubscriptionId }) => {
  const handleStripeRedirect = () => {
    const stripeUrl = `https://dashboard.stripe.com/subscriptions/${stripeSubscriptionId}`;
    window.open(stripeUrl, "_blank");
  };
  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div
        className={` rounded-lg z-10 w-[94%] md:w-[600px] relative dark:bg-dark-black-300 bg-white `}
      >
        <div className={`pb-4 pt-7 px-4  flex justify-between items-start`}>
          <div
            className={` rounded flex-wrap flex justify-start gap-x-7 items-center`}
          >
            <div
              className={`  p-1.5 rounded border-stripe flex justify-center items-center border-stripe w-fit md:w-[10%] dark:bg-dark-black-300 bg-lighter-white`}
            >
              <FaStripe className="text-purple-600   " size={32} />
            </div>
            <div className="w-full md:w-[75%] mt-4 md:mt-0">
              <h2
                className={` text-lg font-extrabold dark:text-dark-gray-350 text-farbe`}
              >
                Open Stripe Account
              </h2>
              {/* <p className={`mt-1 text-sm  dark:text-gray-300 text-gray-600  `}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p> */}
            </div>
          </div>
          <X
            onClick={onClose}
            className="text-gray-500 cursor-pointer w-[30%] md:w-[6%]"
            size={24}
          />
        </div>
        <div className=" px-4 pb-5 w-full flex justify-end items-center mt-7 ">
          <button
            onClick={handleStripeRedirect}
            type="button"
            className="text-white bg-dark-zusatzfarben text-base rounded py-2 px-4 h-11 w-fit hover:bg-light-green   flex justify-center items-center gap-x-2 "
          >
            Take me there
          </button>
        </div>
      </div>
    </section>
  );
};

OpenStripeAccount.propTypes = {
  onClose: PropTypes.func.isRequired,
  stripeSubscriptionId: PropTypes.string.isRequired,
};

export default OpenStripeAccount;
