import {
  ChevronLeft,
  ChevronRight,
  Link2,
  LogOut,
  MailCheck,
  PenLine,
  Download,
} from "lucide-react";
import { useState } from "react";
import EditUser from "./editUser";
import ResetMail from "./resetMail";
import GetPasswordLink from "./getPasswordLink";
import { useAxios } from "../../../../hooks/AxiosContext"; // Import useAxios
import { API_BASE_URL } from "../../../../utils/constants"; // Import API_BASE_URL
import { useEffect } from "react";
import { useLoader } from "../../../../context/LoaderContext";

const User = () => {
  const axiosInstance = useAxios();
  const [users, setUsers] = useState([]); // State to store users
  const [openEmail, setOpenEmail] = useState(false);
  const { startLoading, stopLoading } = useLoader();
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openGetPassword, setOpenGetPassword] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const fetchUsers = async (page = currentPage) => {
    try {
      startLoading();
      const response = await axiosInstance.get(
        `${API_BASE_URL}admin/users?page=${page}&limit=10`
      );
      console.log(response);
      // Update the state with the correct paths
      setUsers(response.data.users); // Access the users array directly
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      stopLoading();
    }
  };

  // Call fetchUsers when the component mounts
  useEffect(() => {
    fetchUsers();
  }, []);

  // Email
  const handleOpenEmail = () => {
    setOpenEmail(true);
  };

  const handleCloseEmail = () => {
    setOpenEmail(false);
  };

  //edit User

  const handleEditUser = (user) => {
    setOpenEditUser(true);
    setSelectedUser(user); // Save the selected user in state
  };
  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  // get password
  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      fetchUsers(newPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      fetchUsers(newPage);
    }
  };

  const handleCloseGetPassword = () => {
    setOpenGetPassword(false);
  };
  const exportToCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,\uFEFF"; // UTF-8 BOM for Excel compatibility

    // Add the header row
    csvContent += "First Name,Last Name,Email,Entity,Role\n";

    // Add the data rows
    users.forEach((user) => {
      const entityName =
        user.entity && user.entity.companyName
          ? user.entity.companyName
          : "No Entity";
      const roleNames =
        user.roles && user.roles.length > 0
          ? '"' + user.roles.map((role) => role.roleName).join(", ") + '"' // Wrap in quotes to handle commas
          : "No Role";
      const rowData = [
        user.firstName,
        user.lastName,
        user.email,
        entityName,
        roleNames,
      ];
      csvContent += rowData.join(",") + "\r\n"; // Carriage return and newline for CSV
    });

    // Create a date string for the filename
    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0]; // Yields "YYYY-MM-DD"

    // Generate the filename with the date included
    const filename = `users_${formattedDate}.csv`;

    // Encode the csvContent and trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <button
        className="btn-trasparent absolute right-5  sm:right-[132px] lg:right-[161px] top-[129px] sm:top-[84px] lg:top-[112px]"
        type="button"
        onClick={exportToCSV}
      >
        <Download className="mr-2" size={18} /> Export
      </button>
      <table className="w-full responsive-table">
        <thead>
          <tr>
            <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              First name
            </th>
            <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              Last name
            </th>
            <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              Email
            </th>
            <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              Entity
            </th>
            <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              Role
            </th>
            <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr
              key={user.userId}
              className="hover:bg-lighter-white lg:border-b dark:border-farbe border-light-zusatzfarben last:border-0 dark:bg-dark-blue-950 bg-gray-100 lg:bg-transparent mb-4 lg:mb-0 rounded lg:rounded-none lg:dark:bg-transparent"
            >
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                title=" First name"
              >
                {user.firstName}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                title="Last name"
              >
                {user.lastName}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                title="Email"
              >
                {user.email}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                title="Title"
              >
                {user.entity.companyName}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                title="Role"
              >
                {user.roles && user.roles.length > 0
                  ? user.roles.map((role) => role.roleName).join(", ")
                  : "No Role"}
              </td>
              <td
                className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                title="Actions"
              >
                <ul className="flex justify-start items-center gap-x-3">
                  {/* <li>
                    <LogOut
                      className="text-farbe cursor-pointer hover:text-dark-zusatzfarben "
                      size={18}
                    />
                  </li> */}
                  <li>
                    <PenLine
                      onClick={() => handleEditUser(user)}
                      className="text-farbe dark:text-gray-300 cursor-pointer hover:text-blue-500  "
                      size={18}
                    />
                  </li>
                  <li>
                    <MailCheck
                      onClick={() => handleOpenEmail()}
                      className="text-farbe dark:text-gray-300 cursor-pointer hover:text-dark-zusatzfarben "
                      size={18}
                    />
                  </li>

                  {/* <li>
                    <Link2
                      onClick={() => handleOpenGetPassword()}
                      className="text-farbe cursor-pointer hover:text-dark-zusatzfarben   "
                      size={18}
                    />
                  </li> */}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="border-t border-[#D3EDEA] dark:border-farbe mt-10 py-3 px-5 flex justify-between items-center">
        <button
          type="button"
          className="button-secondary !w-[84px]"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <ChevronLeft className="text-white mr-2" size={20} /> Prev
        </button>
        <p className="text-sm text-black dark:text-gray-300">
          Page{" "}
          <span className="text-farbe dark:text-dark-gray-350 font-OpenSans-ExtraBold">
            {currentPage}
          </span>{" "}
          of {totalPages}
        </p>
        <button
          type="button"
          className="button-secondary !w-[84px]"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next <ChevronRight className="text-white ml-2" size={20} />
        </button>
      </div>
      {openEditUser && (
        <EditUser
          onClose={handleCloseEditUser}
          user={selectedUser}
          refreshUsers={fetchUsers}
        />
      )}
      {openEmail && <ResetMail onClose={handleCloseEmail} />}
      {openGetPassword && <GetPasswordLink onClose={handleCloseGetPassword} />}
    </>
  );
};

export default User;
