/* eslint-disable react/prop-types */
import { UserCog, X } from "lucide-react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAxios } from "../../../../hooks/AxiosContext"; // Adjust the import path as needed
import { useSnackbar } from "notistack";
import { API_BASE_URL } from "../../../../utils/constants";
const validationSchema = Yup.object().shape({
  categoryNameDe: Yup.string().required("Category name in German is required"),
  categoryNameEn: Yup.string().required("Category name in English is required"),
});
const EditCategory = ({ category, onClose }) => {
  const axiosInstance = useAxios();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      categoryNameDe: category.nameDe,
      categoryNameEn: category.nameEn,
    },
    validationSchema,
    onSubmit: (values) => {
      axiosInstance
        .put(`${API_BASE_URL}admin/categories/${category.categoryId}`, values)
        .then((response) => {
          enqueueSnackbar("Category updated successfully", {
            variant: "success",
          });
          console.log("Category updated:", response.data);
          onClose(); // Close the modal and refresh the category list
        })
        .catch((error) => {
          console.error("Error updating category:", error);
          enqueueSnackbar(error.message, { variant: "error" });
        });
    },
  });

  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div className="rounded-lg z-10 w-[94%] md:w-[560px] relative dark:bg-dark-black-300 bg-white ">
        <div className="py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200">
          <div className="flex justify-start items-center gap-x-4">
            <div
              className={`w-10 h-10 rounded  flex justify-center items-center  drop-shadow-4xl p-2  bg-lighter-zusatzfarben `}
            >
              <UserCog className="text-dark-zusatzfarben  " size={20} />
            </div>
            <div>
              <h2 className={`text-lg  dark:text-dark-gray-350 text-gray-900 `}>
                Edit Category
              </h2>
              <p
                className={`text-sm   dark:text-gray-300 text-gray-600 
                `}
              >
                This is a supporting text.
              </p>
            </div>
          </div>

          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={`pt-4 px-4  `}>
            <div className="flex justify-between items-center w-full mt-6 flex-wrap">
              <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                Category Name En
              </label>
              <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                <input
                  type="text"
                  name="categoryNameDe"
                  placeholder="Category Name DE"
                  onChange={formik.handleChange}
                  value={formik.values.categoryNameDe}
                  className="py-2 px-3 text-gray-700 text-sm md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full"
                />
                {formik.touched.categoryNameDe &&
                  formik.errors.categoryNameDe && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.categoryNameDe}
                    </div>
                  )}
              </div>
            </div>
            <div className="flex justify-between items-center w-full mt-6 flex-wrap">
              <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm md:text-base">
                Category Name DE
              </label>
              <div className="mt-2 md:mt-0 w-full md:w-[60%] relative">
                <input
                  type="text"
                  name="categoryNameEn"
                  placeholder="Category Name EN"
                  onChange={formik.handleChange}
                  value={formik.values.categoryNameEn}
                  className="py-2 px-3 text-gray-700 text-sm md:text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300 dark:border-farbe outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full"
                />
                {formik.touched.categoryNameEn &&
                  formik.errors.categoryNameEn && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.categoryNameEn}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="   border-t dark:border-farbe border-gray-200 pb-4 mt-5 pt-6 w-full md:px-4 flex justify-center gap-x-5   px-4 ">
            <button type="button" className="button-cancel !w-[50%] h-11">
              Reset
            </button>
            <button type="submit" className="button-secondary !w-[50%] h-11">
              Update
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

EditCategory.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default EditCategory;
