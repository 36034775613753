import { ChevronDown, Download, Plus } from "lucide-react";
import Entity from "./entity/entity";
import User from "./user/user";
import { useState } from "react";
import AdminUser from "./adminUser/adminUser";
import AddUser from "./adminUser/addUser";
import { AdminUserProvider } from "../../../hooks/AdminUserContext";

const CentralManagement = () => {
  const [selectedTab, setSelectedTab] = useState("Entity");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [openAddAdminUser, setOpenAddAdminUser] = useState(false);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  // add admin user
  const handleOpenAddAdminUser = () => {
    setOpenAddAdminUser(true);
  };

  const handleCloseAddAdminUser = () => {
    setOpenAddAdminUser(false);
  };

  return (
    <>
      <section className="lg:pt-20 pt-16  w-full h-screen overflow-y-auto dark:bg-dark-black-300 bg-white">
        <div className="py-5 lg:py-8 px-5 lg:px-10 flex justify-between items-center flex-wrap gap-y-5">
          <h2 className="text-xl md:text-2xl  dark:text-dark-gray-350 text-farbe">
            Central management
          </h2>
          <div className="flex justify-start md:justify-end flex-wrap md:flex-nowrap gap-y-2 items-center gap-x-2 md:gap-x-5">
            {selectedTab === "AdminUser" && (
              <button
                className="button-secondary "
                type="button"
                onClick={() => handleOpenAddAdminUser()}
              >
                <Plus className="mr-2" size={18} /> Add
              </button>
            )}
            <div className="relative" onClick={toggleDropdown}>
              <button className="button-secondary " type="button">
                {selectedTab === "User" && "User"}
                {selectedTab === "Entity" && "Entity"}
                {selectedTab === "AdminUser" && "Admin User"}
                <ChevronDown
                  className={`ml-2 ${isDropdownVisible ? "rotate-180 " : " "}`}
                  size={18}
                />
              </button>
              {isDropdownVisible && (
                <div className="absolute z-10 top-[37px]  w-full flex justify-center flex-col items-center bg-dark-zusatzfarben  rounded shadow-md">
                  {selectedTab !== "User" && (
                    <button
                      className=" px-2 w-full pt-2 pb-2   text-white hover:text-gray-200 whitespace-nowrap bg-opacity-80  border-t border-gray-400 first:border-0"
                      onClick={() => handleTabChange("User")}
                    >
                      User
                    </button>
                  )}
                  {selectedTab !== "Entity" && (
                    <button
                      className=" w-full  px-2 pt-2 pb-2  text-white hover:text-gray-200  whitespace-nowrap bg-opacity-80 border-t border-gray-400 first:border-0"
                      onClick={() => handleTabChange("Entity")}
                    >
                      Entity
                    </button>
                  )}
                  {selectedTab !== "AdminUser" && (
                    <button
                      className=" w-full pt-2 pb-2 px-2 text-white hover:text-gray-200 whitespace-nowrap bg-opacity-80 border-t border-gray-400 first:border-0"
                      onClick={() => handleTabChange("AdminUser")}
                    >
                      Admin User
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 px-5 w-full">
          {selectedTab === "Entity" ? (
            <Entity />
          ) : selectedTab === "User" ? (
            <User />
          ) : (
            <AdminUserProvider>
              <AdminUser />
              {openAddAdminUser && (
                <AddUser onClose={handleCloseAddAdminUser} />
              )}
            </AdminUserProvider>
          )}
        </div>
      </section>
    </>
  );
};

export default CentralManagement;
