import { UserRoundCog, X } from "lucide-react";
import PropTypes from "prop-types";
import { useAxios } from "../../../hooks/AxiosContext"; // Adjust the path as necessary
import React, { useState } from "react";
import { API_BASE_URL } from "../../../utils/constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const EditRoles = ({ onClose, roleId, initialData, onRoleAdded }) => {
  const axiosInstance = useAxios();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      roleName: initialData.roleName || "", // Use initial data or fallback to empty string
      description: initialData.description || "",
    },
    validationSchema: Yup.object({
      roleName: Yup.string().required("Name is required"),
    }),
    onSubmit: (values) => {
      axiosInstance
        .put(`${API_BASE_URL}admin/role/edit/${roleId}`, values)
        .then((response) => {
          console.log("Role updated:", response.data);
          enqueueSnackbar("Role updated successfully", { variant: "success" });
          onRoleAdded();
          onClose();
          // Optionally: refresh role list or show success message
        })
        .catch((error) => {
          enqueueSnackbar("Error updated role", { variant: "error" });
          // Optionally: show error message
        });
    },
  });
  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div className="rounded-lg z-10 w-[94%] md:w-[560px] relative dark:bg-dark-black-300 bg-white ">
        <div className="py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200">
          <div className="flex justify-start items-center gap-x-4">
            <div
              className={`w-10 h-10 rounded  flex justify-center items-center  drop-shadow-4xl p-2 bg-lighter-zusatzfarben`}
            >
              <UserRoundCog className="text-dark-zusatzfarben  " size={20} />
            </div>
            <div>
              <h2 className={`text-lg  dark:text-dark-gray-350 text-gray-900 `}>
                Edit role
              </h2>
              <p
                className={`text-sm   dark:text-gray-300 text-gray-600 
                `}
              >
                This is a supporting text.
              </p>
            </div>
          </div>

          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <div className={`py-4 `}>
          <form onSubmit={formik.handleSubmit}>
            <div className="px-4 ">
              <div className="flex justify-between items-center w-full flex-wrap">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350 text-sm">
                  Name
                </label>
                <div className="w-full mt-2 md:mt-0 md:w-[60%] relative">
                  <input
                    type="text"
                    name="roleName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.roleName}
                    placeholder="e.g. Owner"
                    className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 dark:placeholder:text-gray-300 dark:text-gray-300  dark:border-farbe outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full "
                  />
                  {formik.touched.roleName && formik.errors.roleName ? (
                    <div className="text-red-500 text-xs absolute -bottom-4 left-0">
                      {formik.errors.roleName}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-between items-center w-full mt-6 flex-wrap">
                <label className="w-full md:w-[30%] text-gray-700 dark:text-dark-gray-350  text-sm">
                  Description
                </label>
                <div className="w-full mt-2 md:mt-0 md:w-[60%] relative">
                  <input
                    type="text"
                    name="description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    placeholder="e.g. description  "
                    className="py-2 px-3 text-gray-700 text-base placeholder:text-gray-700 outline-none dark:placeholder:text-gray-300 dark:text-gray-300  dark:border-farbe focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl w-full  "
                  />
                </div>
              </div>
            </div>
            <div className=" border-t dark:border-farbe border-gray-200 pb-4 mt-5 pt-6 w-full md:px-4 flex justify-center gap-x-5  py-4 px-4 ">
              <button
                type="button"
                className="button-cancel !w-[50%] h-11"
                onClick={formik.handleReset}
              >
                Reset
              </button>
              <button type="submit" className="button-secondary !w-[50%] h-11">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

EditRoles.propTypes = {
  onClose: PropTypes.func.isRequired,
  roleId: PropTypes.number.isRequired,
  initialData: PropTypes.shape({
    roleName: PropTypes.string,
    description: PropTypes.string,
  }),
  onRoleAdded: PropTypes.func.isRequired,
};

export default EditRoles;
