import { ChevronLeft, ChevronRight, Trash2, PenLine, Plus } from "lucide-react";
import { useState, useEffect } from "react";
import AddCategory from "./addCategory";
import EditCategory from "./EditCategory";
import DeleteCategory from "./deleteCategory";
import { useAxios } from "../../../../hooks/AxiosContext";
//Adjust the import path as needed
import { useSnackbar } from "notistack";
import { API_BASE_URL } from "../../../../utils/constants";

const Category = () => {
  const axiosInstance = useAxios();
  const { enqueueSnackbar } = useSnackbar();
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchCategories = async (page) => {
    try {
      const response = await axiosInstance.get(
        `${API_BASE_URL}admin/categories?page=${page}`
      );
      setCategories(response.data.categories);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    fetchCategories(currentPage);
  }, [currentPage]);

  // Handle pagination
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setOpenEditCategory(true);
  };
  const handleDeleteCategory = (category) => {
    setSelectedCategory(category);
    setOpenDeleteCategory(true);
  };
  return (
    <>
      <section className="lg:pt-20 pt-16  w-full h-screen overflow-y-auto dark:bg-dark-black-300 bg-white">
        <div className=" py-8 px-5 lg:px-10 flex justify-between items-center">
          <h2 className="text-2xl  dark:text-dark-gray-350 text-farbe">
            Category
          </h2>
          <button
            className="button-secondary "
            type="button"
            onClick={() => setOpenAddCategory(!openAddCategory)}
          >
            <Plus className="text-white mr-2" size={20} /> Add
          </button>
        </div>
        <div className="px-5 lg:px-10">
          <table className="w-full responsive-table  ">
            <thead>
              <tr>
                <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
                  Category ID
                </th>

                <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
                  Category Name EN
                </th>
                <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
                  Category Name DE
                </th>
                <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr
                  key={category.categoryId}
                  className="hover:bg-lighter-white lg:border-b dark:border-farbe border-light-zusatzfarben last:border-0 dark:bg-dark-blue-950 bg-gray-100 lg:bg-transparent mb-4 lg:mb-0 rounded lg:rounded-none lg:dark:bg-transparent"
                >
                  <td
                    className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                    title="Category ID"
                  >
                    {category.categoryId}
                  </td>

                  <td
                    className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                    title="Category Name EN"
                  >
                    {category.nameEn}
                  </td>
                  <td
                    className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                    title="Category Name DE"
                  >
                    {category.nameDe}
                  </td>
                  <td
                    className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                    title="Actions"
                  >
                    <ul className="flex justify-start items-center gap-x-3">
                      <li>
                        <PenLine
                          onClick={() => handleEditCategory(category)}
                          className="text-farbe dark:text-gray-300 cursor-pointer hover:text-blue-500  "
                          size={18}
                        />
                      </li>
                      <li>
                        <Trash2
                          onClick={() => handleDeleteCategory(category)}
                          className="text-farbe dark:text-gray-300 cursor-pointer hover:text-dark-zusatzfarben "
                          size={18}
                        />
                      </li>
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="border-t border-[#D3EDEA] dark:border-farbe mt-10 py-3  px-10 flex justify-between items-center">
          <button
            type="button"
            className="button-secondary !w-[84px]"
            onClick={handlePrevPage}
          >
            <ChevronLeft className="text-white mr-2" size={20} /> Prev
          </button>
          <p className="text-sm text-black dark:text-gray-300">
            Page{" "}
            <span className="text-farbe dark:text-dark-gray-350 font-OpenSans-ExtraBold">
              {currentPage}
            </span>{" "}
            of {totalPages}
          </p>
          <button
            type="button"
            className="button-secondary !w-[84px]"
            onClick={handleNextPage}
          >
            Next <ChevronRight className="text-white ml-2" size={20} />
          </button>
        </div>
      </section>
      {openAddCategory && (
        <AddCategory
          onClose={() => {
            setOpenAddCategory(false);
            fetchCategories(currentPage); // Refresh the category list
          }}
        />
      )}
      {openEditCategory && (
        <EditCategory
          category={selectedCategory}
          onClose={() => {
            setOpenEditCategory(false);
            setSelectedCategory(null); // Reset the selected category
            fetchCategories(currentPage); // Refresh the category list
          }}
        />
      )}
      {openDeleteCategory && (
        <DeleteCategory
          category={selectedCategory}
          onClose={() => {
            setOpenDeleteCategory(false);
            setSelectedCategory(null);
            fetchCategories(currentPage);
          }}
        />
      )}
    </>
  );
};

export default Category;
