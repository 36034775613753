import { ChevronDown, ChevronUp, LogOut, Search, Globe } from "lucide-react";
import UserImg from "../../../assets/sidebar/user-riona.png";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const Topbar = ({ isSidebarOpen }) => {
  const navigate = useNavigate();
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  useEffect(() => {
    const storedAdminName = localStorage.getItem("adminName");
    const storedAdminEmail = localStorage.getItem("adminEmail");

    if (storedAdminName) setAdminName(capitalizeFirstLetter(storedAdminName));
    if (storedAdminEmail) setAdminEmail(storedAdminEmail);
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const handleLogout = () => {
    // Clear localStorage
    localStorage.clear();

    // Redirect to home page
    navigate("/login");
  };
  const [isProfileOpen, setProfileOpen] = useState(false);

  const toggleProfile = () => {
    setProfileOpen(!isProfileOpen);
  };

  const profileMenuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      setProfileOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <section
        className={`w-full fixed top-0 left-0 right-0 z-30 border-b dark:bg-farbe hidden md:flex justify-between items-center duration-300 ease-in-out  ${
          isSidebarOpen ? "pl-[280px]" : "pl-24"
        }   py-5 pr-5 
           bg-white border-light-zusatzfarben dark:border-farbe `}
      >
        <div className="flex justify-end items-center gap-x-7 w-full ">
          <div
            className="relative flex items-center justify-end cursor-pointer gap-x-2 h-full"
            onClick={toggleProfile}
          >
            <div className="w-[60%]">
              <h1 className="text-sm  text-farbe dark:text-white">
                {" "}
                {adminName}{" "}
              </h1>
              <p
                className={`text-xs dark:text-gray-200  text-gray-600
            `}
              >
                {adminEmail}
              </p>
            </div>
            <div className="w-[35%] flex justify-end items-center gap-x-2 duration-300">
              <div className="!w-12  rounded-full ">
                <img src={UserImg} alt="Riona" title="Riona" />
              </div>
              {isProfileOpen ? (
                <ChevronUp className="text-dark-zusatzfarben  " />
              ) : (
                <ChevronDown className="text-dark-zusatzfarben" />
              )}
            </div>
            {isProfileOpen && (
              <div
                ref={profileMenuRef}
                className={`absolute w-[205px] border   rounded py-2 shadow top-14 right-0 dark:border-dark-blue-950 overflow-hidden dark:bg-dark-blue-950 bg-white border-light-zusatzfarben`}
              >
                <ul>
                  <li
                    className={`flex items-center justify-between  dark:text-gray-300  px-5 pt-2 pb-1 text-sm    gap-x-3 
                  bg-white hover:bg-gray-100 text-farbe  dark:hover:bg-gray-800 dark:bg-dark-blue-950
                `}
                    onClick={handleLogout}
                  >
                    <LogOut size={22} className="text-dark-zusatzfarben " />{" "}
                    <p className="w-[78%]">logout</p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

Topbar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
};
export default Topbar;
