import Event from "../../../assets/activity-tracking/event-graph.png";
import Activity from "../../../assets/activity-tracking/activity-by-day.png";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useState } from "react";
import { useEffect } from "react";
import { API_BASE_URL } from "../../../utils/constants";
import { useAxios } from "../../../hooks/AxiosContext";
const ActivityTracking = () => {
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const axiosInstance = useAxios();
  useEffect(() => {
    // This function will fetch data when the component mounts and when currentPage changes
    const fetchActivities = async () => {
      try {
        const response = await axiosInstance.get(
          `${API_BASE_URL}admin/activities?page=${currentPage}`
        );
        setActivities(response.data.activities);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching activities:", error);
        // You can handle the error by showing a message to the user if needed
      }
    };

    fetchActivities();
  }, [currentPage, axiosInstance]);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
  return (
    <section className="lg:pt-20 pt-16 w-full h-screen overflow-y-auto dark:bg-dark-black-300 bg-white">
      <div className=" py-5 lg:py-8 px-5 lg:px-10 flex gap-y-3 flex-wrap md:flex-nowrap justify-between items-center">
        <h2 className="text-xl md:text-2xl  dark:text-dark-gray-350 text-farbe">
          Activity Tracking
        </h2>
        {/* <div className="flex justify-start  md:justify-end  items-center gap-x-5">
          <input
            type="date"
            className="text-gray-500 dark:border-farbe dark:bg-farbe dark:text-gray-300 outline-none focus:outline-none text-sm border-none bg-[#F9F9F9] rounded py-2 px-3 drop-shadow-3xl "
          />
          <select className="bg-[#F9F9F9] dark:border-farbe dark:bg-farbe dark:text-gray-300 rounded py-2 px-3 drop-shadow-3xl text-sm text-gray-500 outline-none focus:outline-none border-none">
            <option value="">Event type</option>
          </select>
        </div> */}
      </div>
      {/* <div className="mt-5 px-5 lg:px-10 w-full flex justify-between flex-wrap ">
        <div className="bg-[#F2FAF9] dark:bg-dark-blue-950 rounded-lg p-6 w-full lg:w-[40%] xl:w-[400px]">
          <h2 className="text-[#101828] dark:text-dark-gray-350 text-lg font-OpenSans-bold">
            Event Type
          </h2>
          <div className="flex justify-between items-center mt-5  flex-wrap">
            <div className="w-full md:w-[35%]">
              <img
                src={Event}
                alt="Event Type"
                title="Event Type"
                className="w-full"
              />
            </div>
            <div className="w-full md:w-[35%] mt-5 md:mt-0">
              <p className="text-black dark:text-dark-gray-350 text-sm">
                <span className="w-3 h-3 rounded-full bg-farbe mr-3 inline-block"></span>
                Create -36
              </p>
              <p className="text-black dark:text-dark-gray-350 text-sm mt-4">
                <span className="w-3 h-3 rounded-full bg-green-500 mr-3 inline-block"></span>
                Update -40
              </p>
              <p className="text-black  dark:text-dark-gray-350 text-sm mt-4">
                <span className="w-3 h-3 rounded-full bg-redish mr-3 inline-block"></span>
                Delete -20
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#F2FAF9] dark:bg-dark-blue-950 rounded-lg p-6 w-full mt-5 lg:mt-0 lg:w-[55%] xl:w-[710px]">
          <h2 className="text-[#101828] dark:text-dark-gray-350 text-lg font-OpenSans-bold">
            Activity by Day
          </h2>
          <div className="flex justify-between items-center mt-5 flex-wrap ">
            <div className="w-full md:w-[60%]">
              <img
                src={Activity}
                alt="Activity by Day"
                title="Activity by Day"
                className="w-full"
              />
            </div>
            <div className="w-full md:w-[19%] mt-5 md:mt-0">
              <p className="text-black dark:text-dark-gray-350 text-sm">
                <span className="w-3 h-3 rounded-full bg-farbe mr-3 inline-block"></span>
                Create -36
              </p>
              <p className="text-black dark:text-dark-gray-350 text-sm mt-4">
                <span className="w-3 h-3 rounded-full bg-green-500 mr-3 inline-block"></span>
                Update -40
              </p>
              <p className="text-black dark:text-dark-gray-350 text-sm mt-4">
                <span className="w-3 h-3 rounded-full bg-redish mr-3 inline-block"></span>
                Delete -20
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="w-full mt-10 px-5">
        <table className="w-full responsive-table">
          <thead>
            <tr>
              <th className="px-5 py-2 text-farbe dark:text-dark-gray-350 text-base font-OpenSans-bold text-left">
                Log name
              </th>
              <th className="px-5 py-2 text-farbe dark:text-dark-gray-350 text-base font-OpenSans-bold  text-center">
                Event
              </th>
              <th className="px-5 py-2 text-farbe dark:text-dark-gray-350 text-base font-OpenSans-bold text-center">
                Done By
              </th>
              <th className="px-5 py-2 text-farbe dark:text-dark-gray-350 text-base font-OpenSans-bold text-right">
                Updated at
              </th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity) => (
              <tr
                key={activity.id}
                className="hover:bg-lighter-white lg:border-b dark:border-farbe border-light-zusatzfarben last:border-0 dark:bg-dark-blue-950 bg-gray-100 lg:bg-transparent mb-4 lg:mb-0 rounded lg:rounded-none lg:dark:bg-transparent  "
              >
                <td
                  className="px-5 py-2 text-light-black  dark:text-gray-300 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black  text-left"
                  title="Log name"
                >
                  {activity.logName}
                </td>
                <td
                  className="px-5 py-2 text-farbe dark:text-gray-300 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black   text-center"
                  title=" Event"
                >
                  {activity.event}
                </td>
                <td
                  className="px-5 py-2 text-light-black dark:text-gray-300 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black  text-center"
                  title=" Done By"
                >
                  {activity.doneBy}
                </td>
                <td
                  className="px-5 py-2 text-farbe text-sm dark:text-gray-300 dark:after:text-gray-300 after:text-black  font-OpenSans-bold text-right"
                  title="  Updated at"
                >
                  {new Date(activity.updatedAt).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="border-t dark:border-farbe border-[#D3EDEA] mt-10 py-3 md:px-5  flex justify-between items-center">
          <button
            onClick={handlePrevPage}
            type="button"
            className="button-secondary !w-[84px]"
            disabled={currentPage === 1}
          >
            <ChevronLeft className="text-white mr-2" size={20} /> Prev
          </button>
          <p className="text-sm text-black ">
            Page{" "}
            <span className="text-farbe font-OpenSans-ExtraBold">
              {currentPage}
            </span>{" "}
            of {totalPages}
          </p>
          <button
            onClick={handleNextPage}
            type="button"
            className="button-secondary !w-[84px]"
            disabled={currentPage === totalPages}
          >
            Next <ChevronRight className="text-white ml-2" size={20} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default ActivityTracking;
