import Hints from "../../assets/company/hints-white-logo.png";
import { LockKeyhole, Mail, UnlockKeyhole, User } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Google from "../../assets/admin/google.png"

const SignUP = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <section className="w-full bg-gradient-admin ">
      <div className="w-full xl:max-w-screen-xl lg:container mx-auto px-5 relative flex justify-center items-center min-h-screen flex-col">
        <div className="absolute top-10 left-0">
          <Link to="#">
            <img src={Hints} alt="Hints" title="Hints" />
          </Link>
        </div>
        <div className="bg-white rounded-xl border border-border-gray p-3 sm:p-5 md:p-9 w-full sm:w-[90%] md:w-[540px]">
          <h1 className="text-xl md:text-2xl lg:text-[32px] text-[#333] text-center ">
          Let’s go!
          </h1>
          <form className="mt-5">
          <label className="text-[#666] text-sm md:text-base">Full name</label>
            <div className="mt-2 relative mb-5">
              <input
                type="text"
                className="text-sm w-full outline-none focus:outline-none border border-[#666] rounded-xl py-3 pl-11 pr-5   "
              />
              <User
                className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666]"
                size={20}
              />
            </div>
            <label className="text-[#666] text-sm md:text-base">Email</label>
            <div className="mt-2 relative mb-5">
              <input
                type="text"
                className="text-sm w-full outline-none focus:outline-none border border-[#666] rounded-xl py-3 pl-11 pr-5   "
              />
              <Mail
                className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666]"
                size={20}
              />
            </div>
            <label className="text-[#666] text-sm md:text-base ">Password</label>
            <div className="mt-2 relative">
              <input
                type={showPassword ? "text" : "password"}
                className="text-sm w-full outline-none focus:outline-none border border-[#666] rounded-xl py-3 pl-11 pr-36   "
              />
              {showPassword ? (
                <UnlockKeyhole
                  className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666] cursor-pointer"
                  size={20}
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <LockKeyhole
                  className="top-1/2 -translate-y-1/2 absolute left-4 text-[#666] cursor-pointer"
                  size={20}
                  onClick={togglePasswordVisibility}
                />
              )}
              <p className="text-dark-zusatzfarben text-sm md:text-base font-OpenSans-bold top-1/2 -translate-y-1/2 absolute right-4 cursor-pointer ">
                {showPassword ? "Show" : "Hide"}
              </p>
            </div>

            <button className="button-primary w-full mt-7" type="submit">
            Sign in
            </button>
            <p className="text-[#666] text-center text-sm md:text-base font-OpenSans-bold  mt-3 ">
            --Or --
              </p>
              <button className="border-dark-zusatzfarben text-dark-zusatzfarben text-sm md:text-base border-2 rounded-lg h-12 py-y px-4 text-center flex justify-center items-center gap-x-5  w-full mt-3" type="button">
                <img src={Google} alt="Google" className="max-w-[34px] h-auto "/>
              Sign up with Google
            </button>
          </form>
        </div>
        <p className="mt-5 text-white text-sm md:text-base font-OpenSans-bold tracking-wide ">
        Already using hints? 
          <Link
            to="/login"
            className="text-dark-zusatzfarben font-OpenSans-bold ml-1 text-sm md:text-base"
          >
             Login
          </Link>
        </p>
      </div>
    </section>
  );
};

export default SignUP;
