import Topbar from "./topbar";
import Hints from "../../../assets/sidebar/hints-logo.png";
import HintsIcon from "../../../assets/sidebar/hints.svg";
import {
  ChevronLeft,
  LayoutDashboard,
  Sun,
  ChevronRight,
  Moon,
  UserRoundCog,
  Database,
  Waypoints,
  Activity,
  X,
  Menu,
  Globe,
  Newspaper,
  FilePlus,
  Layers2,
} from "lucide-react";
import useDarkMode from "../../../hooks/useDarkMode";
import { useEffect, useState } from "react";
import Routing from "../../../routes/index";
import { Link, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import MobileMenu from "./mobileMenu";

const Sidebar = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [colorTheme, setTheme] = useDarkMode();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );
  const location = useLocation();
  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkSide(checked);
  };
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [openHumburgerMenu, setOpenHumburgerMenu] = useState(false);
  const [showBlogDropdown, setShowBlogDropdown] = useState(false);

  const menuData = [
    {
      title: "Dashboard",
      icon: <LayoutDashboard size={24} />,
      link: `/app/dashboard`,
    },
    {
      title: "Central management ",
      icon: <Database size={24} />,
      link: `/app/central-management`,
    },
    {
      title: "Blogs",
      icon: <Newspaper size={24} />,
      submenu: [
        {
          icon: <Layers2 size={22} />,
          title: "Category",
          link: "/app/blogs/category",
        },
        {
          icon: <FilePlus size={22} />,
          title: "Blog Article",
          link: "/app/blogs/blog-article",
        },
      ],
    },
    {
      title: "Role & Permissions",
      icon: <UserRoundCog size={24} />,
      link: `/app/roles-and-permission`,
    },
    {
      title: "Activity Tracking",
      icon: <Activity size={24} />,
      link: "/app/activity-tracking",
    },
    // {
    //   title: "Resolution Hub",
    //   icon: <Waypoints fill="#fff" size={24} />,
    //   link: "/app/resolution-hub",
    // },
  ];

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleBlogDropdown = () => {
    setShowBlogDropdown(!showBlogDropdown);
  };
  const handleOpenHumburger = () => {
    setOpenHumburgerMenu(!openHumburgerMenu);
  };
  const handleCloseMobileMenu = () => {
    setOpenHumburgerMenu(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setSidebarOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSubmenuActive = (link) => {
    return location.pathname === link;
  };
  return (
    <>
      <section className="relative flex w-full">
        <div>
          <div
            className={`absolute top-16 cursor-pointer   bg-farbe    border border-lighter-zusatzfarben hidden lg:flex justify-center items-center  duration-300 transition-ease text-white shadow-2xl w-7 rounded z-50 ${
              isSidebarOpen ? "left-[247px]" : "left-[67px]"
            }`}
            onClick={toggleSidebar}
          >
            {isSidebarOpen ? <ChevronLeft /> : <ChevronRight />}
          </div>
          <Topbar isSidebarOpen={isSidebarOpen} />
          <div
            className={`h-screen min-h-screen  hidden md:block ${
              isSidebarOpen ? "w-[260px]" : "w-20"
            } relative z-40 bg-farbe  sidebar-shadow pt-14 pb-10 pr-6  duration-300 transition-ease `}
          >
            {isSidebarOpen ? (
              <img
                src={Hints}
                alt="Hints"
                title="Hints"
                className="max-w-[121px] cursor-pointer pl-6"
              />
            ) : (
              <img
                src={HintsIcon}
                alt="Hints"
                title="Hints"
                className="max-w-[121px] cursor-pointer pl-6"
              />
            )}
            <div className="pt-10">
              <ul>
                {menuData.map((item, index) => (
                  <li
                    key={index}
                    className={` mt-1 relative group text-gray-menu ${
                      index === 0 ? "first:mt-0" : ""
                    }`}
                  >
                    {item.submenu ? (
                      <div >
                        <Link
                          to={item.link}
                          title={item.title}
                          onClick={toggleBlogDropdown}
                          className={`flex justify-start items-center  gap-x-2  pl-6 hover:bg-[#1B4859] py-2  group duration-300 ease-in-out transition capitalize ${
                            location.pathname === item.link
                              ? "bg-[#1B4859] text-gray-menu "
                              : "text-gray-menu"
                          }`}
                        >
                          {item.icon}
                          {isSidebarOpen && (
                            <h2 className={`  text-base `}>{item.title}</h2>
                          )}
                        </Link>
                        <div
                          className={`bg-dark-zusatzfarben  transition  duration-300 rounded-lg py-1.5 px-2 min-w-[130px] w-fit absolute top-0 left-16 z-30  ${
                            isSidebarOpen
                              ? "hidden"
                              : "group-hover:block hidden"
                          }`}
                        >
                          <h2 className="text-sm text-gray-200 whitespace-nowrap ">
                            {item.title}
                          </h2>
                        </div>
                        {showBlogDropdown && (
                          <ul className={`${isSidebarOpen ? "ml-6" : ""}`}>
                            {item.submenu.map((subItem, subIndex) => (
                              <li
                                key={subIndex}
                                className={` text-sm mt-1 relative group text-gray-menu ${
                                  index === 0 ? "first:mt-0" : ""
                                }`}
                              >
                                <Link
                                  to={subItem.link}
                                  className={`flex justify-start items-center  gap-x-2 pl-6 hover:bg-[#1B4859] py-2  group duration-300 ease-in-out transition capitalize ${
                                    location.pathname === subItem.link
                                      ? "bg-[#1B4859] text-gray-menu "
                                      : "text-gray-menu"
                                  }`}
                                >
                                  {subItem.icon}
                                  {isSidebarOpen && (
                                    <h2 className={`  text-sm `}>
                                      {subItem.title}
                                    </h2>
                                  )}
                                </Link>
                                <div
                                  className={`bg-dark-zusatzfarben  transition  duration-300 rounded-lg py-1.5 px-2 min-w-[130px] w-fit absolute top-0 left-16 z-30    ${
                                    isSidebarOpen
                                      ? "hidden"
                                      : "group-hover:block hidden"
                                  }`}
                                >
                                  <h2 className="text-sm text-gray-200 whitespace-nowrap ">
                                    {subItem.title}
                                  </h2>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <>
                        <Link
                          to={item.link}
                          title={item.title}
                          className={`flex justify-start items-center  gap-x-2 pl-6 hover:bg-[#1B4859] py-2  group duration-300 ease-in-out transition capitalize ${
                            location.pathname === item.link
                              ? "bg-[#1B4859] text-gray-menu "
                              : "text-gray-menu"
                          }`}
                        >
                          {item.icon}
                          {isSidebarOpen && (
                            <h2 className={`  text-base `}>{item.title}</h2>
                          )}
                        </Link>
                        <div
                          className={`bg-dark-zusatzfarben  transition  duration-300 rounded-lg py-1.5 px-2 min-w-[130px] w-fit absolute top-0 left-16 z-30    ${
                            isSidebarOpen
                              ? "hidden"
                              : "group-hover:block hidden"
                          }`}
                        >
                          <h2 className="text-sm text-gray-200 whitespace-nowrap ">
                            {item.title}
                          </h2>
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ul>

              <div
                className={`fixed left-0   bottom-3  flex justify-center items-center gap-x-3 toogle-dark ${
                  isSidebarOpen ? "w-fit px-6" : "w-fit "
                }`}
              >
                <div className="relative flex items-center justify-center p-4 rounded-lg ">
                  <Switch
                    checked={darkSide}
                    onChange={toggleDarkMode}
                    className={`${
                      darkSide ? "" : ""
                    } relative inline-flex h-7 w-14 items-center outline-none rounded-full bg-lighter-white`}
                  >
                    <span
                      className={`${
                        darkSide ? "translate-x-7" : "translate-x-1"
                      }  h-6 w-6 transform rounded-full bg-white transition flex justify-center items-center`}
                    >
                      {darkSide ? (
                        <Moon className="text-farbe" size={18} />
                      ) : (
                        <Sun className="text-farbe" size={18} />
                      )}
                    </span>
                  </Switch>
                </div>
                <h4
                  className={`text-white whitespace-nowrap ${
                    isSidebarOpen ? "block" : "hidden"
                  }`}
                >
                  {darkSide ? "Dark mode " : " Light mode"}
                </h4>
              </div>
            </div>
          </div>
          <div className="fixed z-40 flex items-center justify-between w-full px-5 py-5 bg-farbe ">
            <img
              src={Hints}
              alt="Hints"
              title="Hints"
              className="max-w-[110px] cursor-pointer"
            />
            <div className="flex justify-end items-center gap-x-5">
              <div className="flex items-center justify-center cursor-pointer  group relative duration-300 ease-in-out">
                <span>
                  <Globe size={20} className="text-white" />
                </span>
                <div className=" max-w-[60px] rounded   drop-shadow-2xl w-12 duration-300 ease-in-out">
                  <ul className="flex justify-between items-center">
                    <li
                      className={`hover:text-dark-zusatzfarben text-center text-sm px-2 py-2 text-white hover:bg-dark-blue-950  ${
                        selectedLanguage === "en"
                          ? "font-OpenSans-bold"
                          : " hidden group-hover:block"
                      }`}
                      onClick={() => changeLanguage("en")}
                    >
                      En
                    </li>
                    <li
                      className={`hover:text-dark-zusatzfarben text-center text-sm hover:bg-dark-blue-950  px-2 py-2 text-white ${
                        selectedLanguage === "de"
                          ? "font-OpenSans-bold"
                          : "hidden group-hover:block"
                      }`}
                      onClick={() => changeLanguage("de")}
                    >
                      De
                    </li>
                  </ul>
                </div>
              </div>

              {openHumburgerMenu ? (
                <X
                  size={26}
                  className="z-30 text-light-zusatzfarben hover:text-gray-200 "
                  onClick={() => handleOpenHumburger()}
                />
              ) : (
                <Menu
                  size={26}
                  className="z-30 text-light-zusatzfarben hover:text-gray-200 "
                  onClick={() => handleOpenHumburger()}
                />
              )}
            </div>
          </div>
          {openHumburgerMenu && (
            <MobileMenu handleCloseMobileMenu={handleCloseMobileMenu} />
          )}
        </div>
        <Routing />
      </section>
    </>
  );
};

export default Sidebar;
