/* eslint-disable react/prop-types */
import { X, Trash2 } from "lucide-react";
import PropTypes from "prop-types";
import { useAxios } from "../../../../hooks/AxiosContext";
import { API_BASE_URL } from "../../../../utils/constants";

const DeleteBlog = ({ blog, onClose, onDelete }) => {
  const axiosInstance = useAxios(); // Assuming you have a hook/context for Axios

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`${API_BASE_URL}admin/blogs/${blog.blogId}`);
      onClose(); // Close the modal first
      onDelete(); // Then call onDelete to refresh the list
    } catch (error) {
      console.error("Failed to delete blog:", error);
      // Handle error (show error message to user, etc.)
    }
  };
  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div
        className={` rounded-lg z-10 w-[94%] md:w-[400px] relative dark:bg-dark-black-300 bg-white `}
      >
        <div
          className={`py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200 `}
        >
          <div
            className={`w-9 h-9 rounded  flex justify-center items-center  drop-shadow-4xl p-2 bg-red-50  `}
          >
            <Trash2 className="text-redish " size={20} />
          </div>
          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <div
          className={`py-4 px-4 border-b  dark:border-farbe border-gray-200 `}
        >
          <h3
            className={` text-lg text-center dark:text-dark-gray-350 text-gray-900`}
          >
            Are you sure you want to delete Blog Articles?
          </h3>
        </div>
        <div className="   py-4 px-4 w-full flex justify-center ">
          <button
            onClick={handleDelete}
            type="button"
            className="text-white bg-redish text-sm rounded py-2 px-4  w-[48%] hover:bg-red-400  flex justify-center items-center gap-x-2 "
          >
            Delete
          </button>
        </div>
      </div>
    </section>
  );
};

DeleteBlog.propTypes = {
  blog: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteBlog;
