import { useEffect } from "react";
import { useState } from "react";
import { useAxios } from "../../../hooks/AxiosContext";
import { API_BASE_URL } from "../../../utils/constants";
import { Chart as ChartJS } from "chart.js/auto";

import { Line, Bar } from "react-chartjs-2";

const Welcome = () => {
  const [chartData, setChartData] = useState(null);
  const [planData, setPlanNameData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [taskData, setTaskData] = useState(null);
  const axiosInstance = useAxios();
  const [adminName, setAdminName] = useState("");

  const fetchStatusData = async () => {
    const response = await axiosInstance.get(
      `${API_BASE_URL}admin/taskStatusCounts`
    );
    const data = response.data;
    const labels = data.map((item) => item.status);
    const counts = data.map((item) => item.count);

    setTaskData({
      labels,
      datasets: [
        {
          label: "Number of Tasks",
          data: counts,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
          ],
          borderWidth: 1,
        },
      ],
    });
  };
  const fetchPlanData = async () => {
    const response = await axiosInstance.get(
      `${API_BASE_URL}admin/subscriptionPlanCounts`
    );
    const data = response.data;

    // Group data by plan name and sum the counts
    const aggregatedData = data.reduce((acc, item) => {
      acc[item.name] = (acc[item.name] || 0) + parseInt(item.count);
      return acc;
    }, {});

    const labels = Object.keys(aggregatedData);
    const counts = Object.values(aggregatedData);

    setPlanNameData({
      labels,
      datasets: [
        {
          label: "Number of Entities",
          data: counts,
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
        },
      ],
    });
  };

  const fetchReportData = async () => {
    const response = await axiosInstance.get(
      `${API_BASE_URL}admin/reportStatusCounts`
    );
    const data = response.data;
    const labels = data.map((item) => item.status);
    const counts = data.map((item) => item.count);

    setReportData({
      labels,
      datasets: [
        {
          label: "Number of Reports",
          data: counts,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
          ],
          borderWidth: 1,
        },
      ],
    });
  };
  const fetchRevenueData = async () => {
    const response = await axiosInstance.get(
      `${API_BASE_URL}admin/revenueChart`
    );

    setChartData(response.data);
  };

  useEffect(() => {
    const name = localStorage.getItem("adminName");
    if (name) {
      setAdminName(name);
    }
    fetchStatusData();
    fetchPlanData();
    fetchReportData();
    fetchRevenueData();
  }, []);
  const isChartDataReady =
    chartData && chartData.datasets && chartData.datasets.length > 0;

  return (
    <section className="pt-20 w-full h-screen overflow-y-auto dark:bg-dark-black-300 bg-lighter-zusatzfarben ">
      <div className=" py-8 px-10 flex justify-between items-center">
        <h2 className="text-2xl  dark:text-dark-gray-350 text-farbe">
          Welcome, {adminName}
        </h2>
      </div>
      <div className="flex justify-between w-full px-10 mt-5 flex-wrap gap-y-5 lg:gap-y-10 ">
        <div className="w-full lg:w-[49%]  dark:bg-dark-blue-950 bg-white drop-shadow-3xl rounded-lg p-5">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg  text-[#101828] dark:text-dark-gray-350 ">
                Subscriptions
              </h3>
            </div>
          </div>
          {planData ? (
            <Bar data={planData} />
          ) : (
            <div>Loading chart data...</div>
          )}
        </div>
        <div className="w-full lg:w-[49%]  dark:bg-dark-blue-950 bg-white drop-shadow-3xl rounded-lg p-5">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg  text-[#101828]  dark:text-dark-gray-350">
                Monthly Recurring Revenue
              </h3>
            </div>
            {/* <div>
              <input
                type="date"
                className="text-dark-zusatzfarben border-none outline-none focus:outline-none  px-2 py-2 dark:text-dark-gray-350 dark:bg-farbe rounded "
              />
            </div> */}
          </div>
          {isChartDataReady ? (
            <div>
              <Line data={chartData} />
            </div>
          ) : (
            <div>Loading chart data...</div>
          )}
        </div>
        <div className="w-full lg:w-[49%]  bg-white dark:bg-dark-blue-950 drop-shadow-3xl rounded-lg p-5">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg  text-[#101828] dark:text-dark-gray-350 ">
                Submissions
              </h3>
              {/* <div className="flex justify-start items-center gap-x-5 mt-1 flex-wrap gap-y-2">
                <p className="text-[#475467] dark:text-dark-gray-350 text-xs">
                  <span className="w-[10px] h-[10px] bg-farbe mr-1 rounded-full inline-block"></span>
                  New
                </p>
                <p className="text-[#475467]  dark:text-dark-gray-350 text-xs">
                  <span className="w-[10px] h-[10px] bg-yellow-500 mr-1 rounded-full inline-block"></span>
                  Pending
                </p>
                <p className="text-[#475467]  dark:text-dark-gray-350 text-xs">
                  <span className="w-[10px] h-[10px] bg-green-500  mr-1 rounded-full inline-block"></span>
                  Resolved
                </p>
                <p className="text-[#475467]  dark:text-dark-gray-350 text-xs">
                  <span className="w-[10px] h-[10px] bg-redish mr-1 rounded-full inline-block"></span>
                  Declined
                </p>
              </div> */}
            </div>
            {/* <div>
              <input
                type="date"
                className="text-dark-zusatzfarben border-none outline-none focus:outline-none px-2 py-2 dark:text-dark-gray-350 dark:bg-farbe rounded "
              />
            </div> */}
          </div>
          {reportData ? (
            <Bar data={reportData} />
          ) : (
            <div>Loading chart data...</div>
          )}
        </div>
        <div className="w-full lg:w-[49%]  dark:bg-dark-blue-950 bg-white drop-shadow-3xl rounded-lg p-5">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg  text-[#101828] dark:text-dark-gray-350  ">
                Tasks
              </h3>
              {/* <div className="flex justify-start items-center gap-x-5 mt-1">
                <p className="text-[#475467]  dark:text-dark-gray-350 text-xs">
                  <span className="w-[10px] h-[10px] bg-farbe mr-1 rounded-full inline-block"></span>
                  Open
                </p>
                <p className="text-[#475467] dark:text-dark-gray-350 text-xs">
                  <span className="w-[10px] h-[10px] bg-yellow-500 mr-1 rounded-full inline-block"></span>
                  WIP
                </p>
                <p className="text-[#475467] dark:text-dark-gray-350 text-xs">
                  <span className="w-[10px] h-[10px] bg-green-500  mr-1 rounded-full inline-block"></span>
                  Completed
                </p>
              </div> */}
            </div>
            {/* <div>
              <input
                type="date"
                className="text-dark-zusatzfarben border-none outline-none focus:outline-none px-2 py-2 dark:text-dark-gray-350 dark:bg-farbe rounded "
              />
            </div> */}
          </div>
          {taskData ? (
            <Bar data={taskData} />
          ) : (
            <div>Loading chart data...</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Welcome;
