import { Ticket, X } from "lucide-react";
import PropTypes from "prop-types";

const AddNewTicket = ({ onClose }) => {
  return (
    <section className="fixed inset-0 z-50 flex items-center justify-center ">
      <div className="absolute inset-0 bg-gray-800 opacity-70"></div>
      <div className="rounded-lg z-10 w-[94%] lg:w-[900px] relative dark:bg-dark-black-300 bg-white ">
        <div className="py-4 px-4 border-b  flex justify-between items-start dark:border-farbe border-gray-200">
          <div className="flex justify-start items-center gap-x-4">
            <div
              className={`w-10 h-10 rounded  flex justify-center items-center  drop-shadow-4xl p-2 bg-lighter-zusatzfarben `}
            >
              <Ticket className="text-dark-zusatzfarben  " size={20} />
            </div>
            <div>
              <h2 className={`text-lg  dark:text-dark-gray-350 text-gray-900 `}>
                Create Ticket
              </h2>
              <p
                className={`text-sm   dark:text-gray-300 text-gray-600 
                `}
              >
                This is a supporting ticket.
              </p>
            </div>
          </div>

          <X onClick={onClose} className="text-gray-500 cursor-pointer " />
        </div>
        <div className={`py-4   `}>
          <form className="px-4">
            <div className="flex justify-between items-center w-full flex-wrap">
              <label className="md:w-[23%] text-gray-700 dark:text-dark-gray-350 text-sm">
                Status
              </label>
              <select className="py-2 px-3 text-gray-700 dark:text-gray-300 dark:border-farbe text-sm md:text-base placeholder:text-gray-700 outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl mt-2 md:mt-0 md:w-[70%] w-full">
                <option value="progress">In Progress</option>
                <option value="close">Close</option>
                <option value="hold">On Hold</option>
              </select>
            </div>
            <div className="flex justify-between items-center w-full mt-4 flex-wrap">
              <label className="w-full md:w-[23%] text-gray-700 dark:text-dark-gray-350 text-sm">
                Subject
              </label>
              <input
                type="text"
                placeholder="e.g. description  "
                className="py-2 px-3 text-gray-700 text-sm md:text-base placeholder:text-gray-700 dark:text-gray-300 dark:placeholder:text-gray-300 dark:border-farbe outline-none focus:outline-none border border-gray-300 bg-transparent rounded drop-shadow-4xl mt-2 md:mt-0 md:w-[70%] w-full "
              />
            </div>
            <div className="flex justify-between items-center w-full mt-4 flex-wrap">
              <label className="w-full md:w-[23%] text-gray-700 dark:text-dark-gray-350 text-sm">
                Attachment
              </label>
              <div className="upload-btn-wrapper w-full mt-2 md:mt-0 md:w-[70%] flex justify-start relative ">
                <input
                  type="file"
                  className="border-l border-t border-b border-gray-300 rounded-l dark:text-gray-300 dark:placeholder:text-gray-300 dark:border-farbe bg-white drop-shadow-4xl py-2 px-3 w-full text-sm md:text-base placeholder:text-dark-zusatzfarben outline-none text-dark-zusatzfarben  cursor-pointer  "
                  placeholder=""
                />
                <div className="flex justify-start  w-full cursor-pointer ">
                  <div className="rounded-l text-center bg-dark-zusatzfarben  text-white text-sm py-3 px-2 whitespace-nowrap  md:w-[120px]   ">
                    Choose files
                  </div>
                  <div className="border border-gray-300 dark:border-farbe rounded-r w-full"></div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center w-full mt-4 gap-y-4 flex-wrap">
              <label className="w-[100%] text-gray-700 dark:text-dark-gray-350 text-sm">
                Description
              </label>
              {/* <div className="upload-btn-wrapper w-[100%] relative ">
                <div className="dark:hidden block">
                  <Editor
                    initialValue="<p>Write  the message here!</p>"
                    init={{
                      height: 250,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style: "body {  font-size:14px }",
                    }}
                  />
                </div>
                <div className="black dark:block hidden">
                  <Editor
                    initialValue="<p>Write  the message here!</p>"
                    init={{
                      height: 250,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  /> 
                </div>
              </div>*/}
            </div>
          </form>
        </div>
        <div className="   border-t dark:border-farbe border-gray-200 pb-4 mt-5 pt-6 w-full md:px-4 flex justify-center gap-x-5  py-4 px-4 ">
          <button type="button" className="button-cancel !w-[50%] h-11">
            Reset
          </button>
          <button type="button" className="button-secondary !w-[50%] h-11">
            Add
          </button>
        </div>
      </div>
    </section>
  );
};

AddNewTicket.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddNewTicket;
