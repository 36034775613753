import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { LoaderProvider } from "./context/LoaderContext.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
  <LoaderProvider>
    <App />
  </LoaderProvider>
);
