import {
  Key,
  PenLine,
  Plus,
  Trash2,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { useState, useEffect } from "react";
import AddNewRoles from "./addNewRoles";
import AllPermission from "./allPermissions";
import EditRoles from "./editRoles";
import DeleteRoles from "./deleteRoles";
import { API_BASE_URL } from "../../../utils/constants";
import { useAxios } from "../../../hooks/AxiosContext"; // Adjust the path as necessary
import Pagination from "../../../utils/pagination";
import { useSnackbar } from "notistack";
import { useLoader } from "../../../context/LoaderContext";

const Roles = () => {
  const axiosInstance = useAxios();
  const { startLoading, stopLoading } = useLoader();
  const [openAddNewRoles, setOpenAddNewRoles] = useState(false);
  const [openAllPermission, setOpenAllPermission] = useState(false);
  const [openEditRoles, setOpenEditRoles] = useState(false);
  const [openDeleteRoles, setOpenDeleteRoles] = useState(false);
  const [roles, setRoles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRole, setSelectedRole] = useState(null); // Add this state

  // add new roles

  const handleAddNewRoles = () => {
    setOpenAddNewRoles(true);
  };

  const handleCloseNewRoles = () => {
    setOpenAddNewRoles(false);
  };

  //permission

  const handleAllPermission = (role) => {
    setSelectedRole(role); // Set the selected role
    setOpenAllPermission(true); // Open the AllPermission component
  };

  const handleCloseAllPermission = () => {
    setOpenAllPermission(false);
  };

  // edit roles

  const handleCloseEditRoles = () => {
    setOpenEditRoles(false);
  };

  //delete
  const refreshRolesList = () => {
    fetchRoles(currentPage); // re-fetch the roles
  };
  const handleDeleteRoles = (roleId) => {
    setSelectedRoleId(roleId);
    setOpenDeleteRoles(true);
  };

  const handleEditRoles = (role) => {
    setSelectedRole(role); // Assuming you have a state for storing selected role
    setOpenEditRoles(true);
  };

  const deleteRole = (roleId) => {
    axiosInstance
      .delete(`${API_BASE_URL}admin/role/${roleId}`) // API call to delete the role
      .then((response) => {
        enqueueSnackbar("Role deleted successfully", { variant: "success" });
        console.log("Role deleted:", response.data);
        refreshRolesList(); // Refresh the list after deletion
        setOpenDeleteRoles(false); // Close the deletion confirmation modal
      })
      .catch((error) => {
        enqueueSnackbar("Error deleting role", { variant: "error" });

        // Optionally show an error message to the user
      });
  };

  const fetchRoles = async (page = 1) => {
    try {
      startLoading();
      const response = await axiosInstance
        .get(`${API_BASE_URL}admin/role`, {
          params: { page, limit: 10 },
        })
        .then((response) => {
          setRoles(response.data.roles);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.currentPage);
        });
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      fetchRoles(newPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      fetchRoles(newPage);
    }
  };
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <>
      {openAllPermission ? (
        <AllPermission
          onClose={handleCloseAllPermission}
          roleId={selectedRole.roleId}
        />
      ) : (
        <section className="lg:pt-20 pt-16 w-full h-screen overflow-y-auto dark:bg-dark-black-300 bg-white">
          {/* <div className="py-5 lg:py-8 lg:px-10 px-5 flex justify-between items-center">
            <h2 className="text-xl md:text-2xl  dark:text-dark-gray-350 text-farbe">
              Roles
            </h2>
            <button
              className="button-secondary gap-x-2"
              type="button"
              onClick={() => handleAddNewRoles()}
            >
              <Plus className="text-white" size={20} /> Add
            </button>
          </div> */}
          <div className="w-full px-5 lg:px-10 mt-5">
            <table className="w-full responsive-table ">
              <thead>
                <tr>
                  <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
                    ID
                  </th>
                  <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
                    Name
                  </th>
                  <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
                    Description
                  </th>
                  <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
                    Created At
                  </th>
                  {/* <th className="text-farbe dark:text-dark-gray-350 px-5 py-3 text-base font-OpenSans-bold text-left">
                    Actions
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {roles.map((role) => (
                  <tr
                    key={role.roleId}
                    className="hover:bg-lighter-white lg:border-b dark:border-farbe border-light-zusatzfarben last:border-0 dark:bg-dark-blue-950 bg-gray-100 lg:bg-transparent mb-4 lg:mb-0 rounded lg:rounded-none lg:dark:bg-transparent "
                  >
                    <td
                      className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                      title="  ID"
                    >
                      {role.roleId}
                    </td>
                    <td
                      className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                      title="Name"
                    >
                      {role.roleName}
                    </td>
                    <td
                      className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                      title="Description"
                    >
                      {role.description}
                    </td>
                    <td
                      className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                      title="Created At"
                    >
                      {formatDate(role.createdAt)}
                    </td>
                    {/* <td
                      className="text-light-black dark:text-gray-300 px-5 py-3 text-sm font-OpenSans-bold dark:after:text-gray-300 after:text-black text-left"
                      title="Actions"
                    >
                      <Key
                        className="text-farbe  dark:text-gray-300 cursor-pointer hover:text-dark-zusatzfarben "
                        size={18}
                        onClick={() => handleAllPermission(role)}
                      /> */}

                    {/* <li>
                          <PenLine
                            className="text-farbe  dark:text-gray-300 cursor-pointer hover:text-blue-500  "
                            size={18}
                            onClick={() => handleEditRoles(role)}
                          />
                        </li>
                        <li>
                          <Trash2
                            className="text-farbe  dark:text-gray-300 cursor-pointer hover:text-redish "
                            size={18}
                            onClick={() => handleDeleteRoles(role.roleId)}
                          />
                        </li> */}
                    {/* </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className="border-t border-[#D3EDEA] dark:border-farbe mt-10 py-3 px-5 flex justify-between items-center">
            <button
              type="button"
              className="button-secondary !w-[84px]"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <ChevronLeft className="text-white mr-2" size={20} /> Prev
            </button>
            <p className="text-sm text-black dark:text-gray-300">
              Page{" "}
              <span className="text-farbe dark:text-dark-gray-350 font-OpenSans-ExtraBold">
                {currentPage}
              </span>{" "}
              of {totalPages}
            </p>
            <button
              type="button"
              className="button-secondary !w-[84px]"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next <ChevronRight className="text-white ml-2" size={20} />
            </button>
          </div> */}
        </section>
      )}

      {openAddNewRoles && (
        <AddNewRoles
          onClose={handleCloseNewRoles}
          onRoleAdded={refreshRolesList}
        />
      )}
      {openEditRoles && selectedRole && (
        <EditRoles
          onClose={handleCloseEditRoles}
          roleId={selectedRole.roleId}
          initialData={{
            roleName: selectedRole.roleName,
            description: selectedRole.description,
          }}
          onRoleAdded={refreshRolesList}
        />
      )}
      {openDeleteRoles && (
        <DeleteRoles
          onClose={() => setOpenDeleteRoles(false)}
          onDelete={deleteRole}
          roleId={selectedRoleId}
        />
      )}
    </>
  );
};

export default Roles;
