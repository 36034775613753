import React, { createContext, useContext } from "react";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";

export const AxiosContext = createContext();

export const useAxios = () => useContext(AxiosContext);

export const AxiosProvider = ({ children }) => {
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
  });

  // Add an interceptor to inject the token into each request
  axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("authToken");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  );
};
